import Vue from 'vue'
import App from './App.vue'

import router from "./router/index"
import store from "./store/index"

Vue.config.productionTip = false

//vant图形库
import Vant from 'vant';
import 'vant/lib/index.css';

//导入自定义的插件
import plugins from './utils/plugins'

//导入vant图形库
Vue.use(Vant);

//使用自定义的插件
Vue.use(plugins)

//加载车牌号tap
Vue.use(require("@/components/carkeyboard/js/tap"))

//路由守卫
function isSyncAccountInfo() {
      if ((store.state.loginFlag != true)
          &&(localStorage.getItem("type") != null)
          &&(localStorage.getItem("token") != null)) {
        return true;
      }

      return false;
}

function isLoginState() {
  if (store.state.loginFlag == true) {
    return true;
  }

  return false;
}

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '访客移动端';

  if ((to.name === "guide")||(to.name === "generalguide")||(to.name === "qywxguide")||
	    (to.name === 'accountsync')||(to.name === "login")||(to.name === 'useragreement')||(to.name === 'detailroute')) {
	  next()
  } else {
    if (isSyncAccountInfo()) {
      next({ name: 'accountsync' });
    } if ((!isLoginState())&&(to.name !== 'home')) {
      next({ name: 'home' });
    } else {
      next();
    }
  }
})

//添加meta标签，禁止屏幕缩放
const meta = document.createElement('meta')
meta.name = 'viewport'
meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
document.getElementsByTagName('head')[0].appendChild(meta)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
