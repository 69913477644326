export const form_parse_mixin_table = {
    methods: {
        // 常用字段 type 固定如下，这些字段有特殊用途，比如reason可以限制签出时间：
        // 1.来访事由：reason
        // 2.来访单位：company
        // 3.拜访地址：address
        // 4.车牌号码：car
        // 5.备注：comments
        // 6.通信人：accompany
        parseFormType(form) {
            for (let i = 0; i < form.length; i++) {
                form[i].required_original = null
                form[i].value_original = null

                switch(form[i].type) {
                    case "beginTime":
                        form[i].realType = "beginTime"
                        break;

                    case "endTime":
                        form[i].realType = "endTime"
                        break;

                    case "reason":
                        form[i].realType = "picker"
                        break;

                    case "company":
                        form[i].realType = "input"
                        break;

                    case "address":
                        form[i].realType = "input"
                        break;

                    case "car":
                        form[i].realType = "car"//单独的类型，采用车牌号输入法
                        break;

                    case "comments":
                        form[i].realType = "input"
                        break;

                    case "accompany":
                        form[i].realType = "accompany"
                        this.accompanyFlage = true
                        break;

                    case "input":
                        form[i].realType = "input"
                        break;

                    case "picker":
                        form[i].realType = "picker"
                        break;

                    case "file":
                        form[i].realType = "file"
                        break;

                    case "timer":
                        form[i].realType = "timer"
                        break;

                    case "number":
                        form[i].realType = "number"
                        break;

                    default:
                        this.printDebug("error ----- no support field type : ", form[i])
                        break;
                }
            };
            
            return form
        },

        parsePickerValue(valueList) {
            let list = JSON.parse(valueList)
            
            let values = []

            list.forEach(item => {
                values.push(item.value)
            })

            return values
        },

        parsePickerDepend(selectValue) {
            let valueList = JSON.parse(this.currentForm.valueList)

            this.printDebug("valueList = ", valueList)
            
            let selected
            for (let i = 0; i < valueList.length; i++) {
                if (valueList[i].value == selectValue) {
                    selected = valueList[i]
                    break
                }
            }
            
            this.printDebug("selected = ", selected)
            
            if (selected.hasOwnProperty("dependField") && (selected.dependField != null)) {
                for (let i = 0; i < this.applyForm.length; i++) {
                    let formItem = this.applyForm[i]
    
                    if (formItem.id == selected.dependField) {
                        formItem.required_original = formItem.required
                        formItem.required = 1
                        
                        if ((selected.hasOwnProperty("isReadyOnly"))
                            &&(selected.hasOwnProperty("readyValue"))
                            &&(selected.isReadyOnly == 1)) {
                                formItem.value_original = formItem.value
                                formItem.value = selected.readyValue
                        }
                        break
                    }
                }
            }
            
            for (let i = 0; i < valueList.length; i++) {
                let valueItem = valueList[i]

                this.printDebug("orignal valueItem => ", valueItem)

                if (valueItem.value == selected.value) {
                    continue
                }

                if (!valueItem.hasOwnProperty("dependField") || (valueItem.dependField == null)) {
                    continue
                }

                this.printDebug("process valueItem => ", valueItem)

                for (let j = 0; j < this.applyForm.length; j++) {
                    let formItem = this.applyForm[j]

                    this.printDebug("formItem = ", formItem)

                    if (formItem.id == valueItem.dependField) {
                        this.printDebug("process formItem = ", formItem)

                        formItem.required = 
                                formItem.required_original != null ? formItem.required_original : formItem.required

                        formItem.value = 
                                formItem.value_original != null ? formItem.value_original : formItem.value
                    }
                }
            }

            console.log("this.applyForm = ", this.applyForm)
        },

        parseCheckoutTime() {
            let checkoutTime

            for (let i = 0; i < this.applyForm.length; i++) {
                let item = this.applyForm[i]

                if (item.type === "reason") {
                    let valueList = JSON.parse(item.valueList)
                    
                    for (let j = 0; j < valueList.length; j++) {
                        if ((valueList[j].hasOwnProperty("signOutTime"))&&
                            (valueList[j].value == item.value)) {
                            checkoutTime = valueList[j].signOutTime
                            break;
                        }
                    }

                    break;
                }
            }

            return checkoutTime
        },

        addFormVerifyRules() {
            for (let i = 0; i < this.applyForm.length; i++) {
                let item = this.applyForm[i]
                
                if (item.required == 1) {
                    item.rule = [{required: true}];
                } else {
                    item.rule = [{required: false}];
                }
            }
        },
    }
}
