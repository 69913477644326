<template>
    <div>
        <van-dialog v-if="loginState == true" class="qr-dialog" v-model="showQRDialog" :show-confirm-button="false" :closeOnClickOverlay="true">
            <VueQr draggable="false" :correctLevel="3" :dotScale="1" :margin="15" :size="256"
                    :text="qrText"/>
        </van-dialog>
        
        <div class="manage">
            <div class="title">
                {{ title }}
            </div>

            <van-row v-if="loginState == false" class="content" @click="loginClick()">
                <van-col span="2">
                </van-col>

                <van-col span="20" class="single">
                    <van-image round style="width:80px; height: 80px" :src="require('../assets/header.jpg')"/>
                    <h4 style="color:red">请 登 录</h4>
                    <van-icon name="arrow" size="30px"/>
                </van-col>

                <van-col span="2">
                </van-col>
            </van-row>

            <van-row v-else class="content">
                <van-col span="1">
                </van-col>

                <van-col span="14" class="left">
                    <van-image round style="width:80px; height: 80px" :src="require('../assets/header.jpg')"/>
                    <van-icon name="arrow" size="30px" @click="accountDetailClick()"/>
                </van-col>
                
                <van-col span="1">
                </van-col>

                <van-col span="7" class="right">
                    <van-image style="width:60px; height: 60px"
                            :src="require('../assets/qr-code.png')" @click="QRImgclick()"/>
                    <h3 style="color:gray; padding:0px; margin:0px">身份码</h3>
                </van-col>

                <van-col span="1">
                </van-col>
            </van-row>
        </div>

        <div v-if="loginState == true" class="welcome">
            <van-button plain round type="primary" size="small" style="margin-left:10px; margin-right:10px">
                {{ welcomeTitle }}
            </van-button>
            <h4>{{ welcomeMsg }}</h4>
        </div>
    </div>
</template>

<script>
import VueQr from "vue-qr";
import { aesEnc, aesDec } from "@/utils/cryptoUtils"
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {
            showQRDialog : false
        }
    },

    computed: {
        ...mapGetters(['loginState']),
        ...mapState(['accountType', 'visitor', 'employee',
                        'systemName', 'homeWelcome']),

        title() {
            if ((this.systemName == null) || (this.systemName === "")) {
                return "访客系统"
            }

            return this.systemName
        },

        welcomeTitle() {
            if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
                return this.employee.name !== "" ? this.employee.name : "员工信息";
            } else {
                return this.visitor.name !== "" ? this.visitor.name : "访客信息";
            }
        },

        welcomeMsg() {
            if ((this.homeWelcome == null) || (this.homeWelcome === "")) {
                return "欢迎光临"
            }

            return this.homeWelcome
        },

        qrText() {
            let type
            let code
            let time = new Date()

            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                type = this.ACCOUNT_TYPE_VISITOR
                code = this.visitor.code
            } else {
                type = this.ACCOUNT_TYPE_EMPLOYEE
                code = this.employee.code
            }

            let qr_text = {
                "type": type,
                "code": code,
                "time": time
            }
            
            this.printDebug("解密后的内容", aesDec(aesEnc(JSON.stringify(qr_text))))
            this.printDebug("content => ", aesEnc(JSON.stringify(qr_text)))
            
            return aesEnc(JSON.stringify(qr_text))
        }
    },

    components: {
        VueQr
    },

    methods: {
        loginClick() {
          this.$router.push({ name : "login" })
        },

        accountDetailClick() {
            this.$router.push({ name : "accountdetail" })
        },

        QRImgclick() {
            this.showQRDialog = true
        },
    }
}
</script>

<style lang="less" scoped>

.qr-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

.manage {
    display: flex;
    flex-direction: column;
    
    background: rgb(81, 126, 230);
    height: 200px;
    
    .title {
        margin: 20px 0px 0px 20px;//上 右 下 左

        font-size: 18px !important;
        color: white;
    }

    .content {
        // height: 160px;
        margin: 30px 0px 0px 0px;//上 右 下 左

        .single {
            background: rgb(222, 231, 248);

            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 100px;

            // margin: 0px 20px 0px 20px;//上 右 下 左

            // background: red;
            // border:2px solid rgb(81, 126, 230);
        }

        .left {
            background: rgb(222, 231, 248);

            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 100px;

            // margin: 0px 20px 0px 20px;//上 右 下 左

            // background: red;
            // border:2px solid rgb(81, 126, 230);
        }

        .right {
            background: rgb(222, 231, 248);

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100px;
            // border:2px solid rgb(81, 126, 230);
        }
    }

}

.welcome {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #efefef;

    margin-top : 10px;
    margin-bottom : 10px;
}

</style>