<template>
    <div class="main">
        <van-loading color="#1989fa" size="48px"></van-loading>
    </div>
</template>

<script>
export default {
    mounted() {
        let employeeLogin = this.$route.query.employee_login

        if (employeeLogin === "qywx") {
            this.$router.push({name: "qywxguide"})
        } else {
            this.$router.push({name: "generalguide"})
        }
    }
}
</script>

<style lang="less" scoped>

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

</style>
