import CryptoJS from 'crypto-js'

function getCryptoKey() {
    const date = new Date()
    
    let year = date.getFullYear();

    let month = date.getMonth() + 1;
    month = month <= 9 ? "0" + month : month

    let day = date.getDate();
    day = day <= 9 ? "0" + day : day

    let keyStr = year + "" + month + "" + day

    keyStr += keyStr

    console.log("keyStr => ", keyStr)

    return keyStr
}

export const aesEnc = (clearText) => {
    let keyStr = getCryptoKey()//密钥
    
    let srcs = CryptoJS.enc.Utf8.parse(clearText) //解析明文，字符串到数组转换
    let key = CryptoJS.enc.Utf8.parse(keyStr) //  字符串到数组转换，解析秘钥

    // mode:加密方式；padding:填充方式；iv便宜向量（可选）
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { 
        mode: CryptoJS.mode.ECB, 
        padding: CryptoJS.pad.Pkcs7 
    })

    //加密后的结果是对象，要转换为文本
    return encrypted.toString()
}

export const aesDec = (clearText) => {
    let keyStr = getCryptoKey()//密钥
    let key = CryptoJS.enc.Utf8.parse(keyStr) //  字符串到数组转换，解析秘钥

    var decrypt = CryptoJS.AES.decrypt(clearText, key, { 
        mode: CryptoJS.mode.ECB, 
        padding: CryptoJS.pad.Pkcs7 
    })

    //  数组到字符串转换
    return decrypt.toString(CryptoJS.enc.Utf8)
}
