<template>
    <div>
        <div class="header">
            <van-tabs type="card" v-model="tab_index" @click="onTabClick">
                <van-tab title="审批中"></van-tab>
                <van-tab title="已审批"></van-tab>
                <van-tab title="已到访"></van-tab>
            </van-tabs>
        </div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <div v-if="validDataStatus">
                <van-form class="valid-form" v-for="(item, index) in validList" :key="index">
                    <div>
                        <div class="form-header">
                            <div style="font-size: 14px !important; color: #303133; font-weight: 700;">
                                {{item.employeeInfo}}
                            </div>
                            
                            <div style="font-size: 14px !important; color: gray;">
                                {{item.createTime | fullTimeFilter()}}
                            </div>
                        </div>
                        
                        <van-field colon readonly v-model="item.pinCode" label="访客码"/>
                        <van-field colon readonly v-model="item.typeStr" label="申请类型"/>

                        <div v-if="tab_index == 2">
                            <van-field colon readonly v-model="item.enterDateTime" label="签到时间"/>
                            <van-field colon readonly v-model="item.leaveDateTime" label="签离时间"/>
                        </div>

                        <div v-else>
                            <van-field colon readonly v-model="item.beginDateTime" label="开始时间"/>
                            <van-field colon readonly v-model="item.endDateTime" label="结束时间"/>
                        </div>
                        
                        <div class="form-footer">
                            <van-tag size="mini" plain round type="danger">{{ item.relatedStr }}</van-tag>
                            
                            <div>
                                <van-button v-if="tab_index == 0 && item.state == 'CANCEL'"
                                            plain round disabled size="small" type="danger" style="margin-right:10px">
                                    已经撤销
                                </van-button>

                                <van-button v-if="showCancelButton(item)" @click="cancel(item)"
                                            plain size="small" type="info" style="margin-right:10px">
                                    撤销申请
                                </van-button>
                                    
                                <van-button plain size="small" type="info" @click="detail(item)">
                                    查看详情
                                </van-button>
                            </div>
                        </div>
                    </div>
                </van-form>
            </div>

            <div v-else style="text-align:center; height: 60px; line-height: 60px; color: gray">
                没有待处理任务
            </div>
            
            <van-divider dashed :style="{color: '#909399', borderColor: '#909399', padding: '20px'}" 
                            @click="showHistoryData()">
                <div v-if="showHistory == false" class="divider_history">
                    <p style="color:#F56C6C">加载过期记录</p>

                    <van-image style="width:30px; height:30px" fit="cover" alt="Avatar"
                                :src="require('../../assets/select.png')"/>
                </div>

                <div v-else class="divider_history">
                    <p style="color:#F56C6C">过期记录</p>

                    <van-image style="width:20px; height:25px;" fit="cover" alt="Avatar"
                                :src="require('../../assets/selected.png')"/>
                </div>
            </van-divider>
            
            <van-list v-if="showHistory == true" v-model="loading" @load="loadHistory()"
                        :finished="finished" finished-text="没有更多了"
                        :error.sync="error" error-text="请求失败，点击重新加载">
                <van-form class="invalid-form" v-for="(item, index) in invalidList" :key="index">
                    <div>
                        <div class="form-header">
                            <div style="font-size: 14px !important; color: gray; font-weight: 700;">
                                {{item.employeeInfo}}
                            </div>
                            
                            <div style="font-size: 14px !important; color: gray;">
                                {{item.createTime | fullTimeFilter()}}
                            </div>
                        </div>
                        
                        <van-field colon readonly v-model="item.pinCode" label="访客码"/>
                        <van-field colon readonly v-model="item.typeStr" label="申请类型"/>

                        <div v-if="tab_index == 2">
                            <van-field colon readonly v-model="item.enterDateTime" label="签到时间"/>
                            <van-field colon readonly v-model="item.leaveDateTime" label="签离时间"/>
                        </div>

                        <div v-else>
                            <van-field colon readonly v-model="item.beginDateTime" label="开始时间"/>
                            <van-field colon readonly v-model="item.endDateTime" label="结束时间"/>
                        </div>
                        
                        <div class="form-footer">
                            <van-tag size="mini" plain round>{{ item.relatedStr }}</van-tag>
                            
                            <div>
                                <van-button plain size="small" @click="detail(item)">
                                    查看详情
                                </van-button>
                            </div>
                        </div>
                    </div>
                </van-form>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { cancelApply,
    getValidPendingAppliesByVisitorId, getInvalidPendingAppliesByVisitorId,
    getValidFinishAppliesByVisitorId, getInvalidFinishAppliesByVisitorId } from '@/api/apply-api'

import { getValidRecordsByVisitorId, getInvalidRecordsByVisitorId } from '@/api/record-api'

export default {
    mounted() {
        this.routeToTab(this.$route.params.active)
    },

    data() {
        return {
            validList: [],
            invalidList: [],
            
            showHistory: false,
            
            tab_index: 0,
            active_tab: null,

            //---下面是下拉刷新---
            refreshing: false,

            //---下面是加载更多历史数据---
            loading: false,
            finished: true,
            error: false,

            //-------下面是分页参数--------
            // 当前页码值
            current: 1,
            // 每页显示数
            size: 5
        }
    },

    computed: {
        ...mapState(['visitor']),

        validDataStatus() {
            if ((this.validList == null) || (this.validList.length <= 0)) {
                return false
            }

            return true
        }
    },

    methods: {
        showCancelButton(item) {
            if ((this.tab_index === 0)
                    &&(item.state !== 'CANCEL')
                    &&(item.visitorId === this.visitor.id)) {
                return true;
            }

            return false;
        },

        cancel(item) {
            this.printDebug("approve cancel item = ", item)

            if (item.state !== "NONE") {
                this.$toast.fail("无法撤销，在审核中")
                return
            }
            
            let apply = {
                "id" : item.id,
                "visitorId": this.visitor.id
            }
            
            cancelApply(apply).then(({data}) => {
                this.printDebug("data = ", data)

                let code = data.code
                
                if (code === 200) {
                    this.$toast.success("撤销成功")
                } else {
                    this.$toast.fail("无法撤销，在审核中")
                }

                item.state = data.data.state
            }).catch(_ => {
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        },
        
        detail(item) {
            this.printDebug("apply detail item = ", item)
            
            this.$router.push({ name: "recorddetail", 
                                params: {
                                    source : 'visitorrecords', 
                                    type : this.RECORD_OPS_DETAIL, 
                                    active: this.active_tab, 
                                    data : item} })
        },

        routeToTab(state) {
            switch(state) {
                case this.RECORD_TAB_PROCESS:
                    this.onTabClick(0)
                    break;
                case this.RECORD_TAB_FINISH:
                    this.onTabClick(1)
                    break;
                case this.RECORD_TAB_ENTER:
                    this.onTabClick(2)
                    break;
                default:
                    this.onTabClick(0)
            }
        },

        onTabClick(index, title) {
            this.validList = []
            this.invalidList = []

            this.showHistory = false

            this.loading = false
            this.finished = true
            this.error = false

            this.current = 1
            this.size = 5

            if (index === 0) {
                this.tab_index = 0
                this.active_tab = this.RECORD_TAB_PROCESS

                this.getValidPendingData()
            } else if (index === 1) {
                this.tab_index = 1
                this.active_tab = this.RECORD_TAB_FINISH

                this.getValidFinishData()
            } else {
                this.tab_index = 2
                this.active_tab = this.RECORD_TAB_ENTER

                this.getValidRecordsData()
            }
        },

        showHistoryData() {
            if (this.showHistory === false) {
                this.loading = false
                this.finished = false
                this.error = false
            } else {
                this.loading = false
                this.finished = true
                this.error = false
            }

            this.showHistory = !this.showHistory
            this.current = 1
            this.size = 5
            this.invalidList = []
        },

        onRefresh() {
            this.onTabClick(this.tab_index, null)
            this.refreshing = false
        },

        loadHistory() {
            console.log("触发loading，加载更多历史数据")
            
            if (this.tab_index === 0) {
                this.getInvalidPendingData()
            } else if (this.tab_index === 1) {
                this.getInvalidFinishData()
            } else {
                this.getInvalidRecordsData()
            }
        },
        
        getValidPendingData() {
            getValidPendingAppliesByVisitorId().then(({data}) => {
                this.printDebug("PendingApplies data =>", data.data)
                
                this.validList = data.data

                this.initApplyShowInfo(this.validList);
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getInvalidPendingData() {
            let page = {
                "current" : this.current, 
                "size" : this.size
            }

            this.printDebug("load page = ", this.current)
            
            getInvalidPendingAppliesByVisitorId(page).then(({data}) => {
                this.printDebug("InvalidPendingApplies data =>", data.data)
                
                this.doInvalidAppliesResponse(data.data)
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getValidFinishData() {
            getValidFinishAppliesByVisitorId().then(({data}) => {
                this.printDebug("FinishApplies data =>", data.data)
                
                this.validList = data.data

                this.initApplyShowInfo(this.validList);
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getInvalidFinishData() {
            let page = {
                "current" : this.current, 
                "size" : this.size
            }

            this.printDebug("load page = ", this.current)

            getInvalidFinishAppliesByVisitorId(page).then(({data}) => {
                this.printDebug("InvalidFinishApplies data =>", data.data)
                
                this.doInvalidAppliesResponse(data.data)
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getValidRecordsData() {
            getValidRecordsByVisitorId().then(({data}) => {
                this.printDebug("ValidRecords data =>", data.data)
                
                this.validList = data.data

                this.printDebug("this.validList =>", this.validList)

                this.initRecordShowInfo(this.validList);
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getInvalidRecordsData() {
            let page = {
                "current" : this.current, 
                "size" : this.size
            }

            this.printDebug("load page = ", this.current)

            getInvalidRecordsByVisitorId(page).then(({data}) => {
                this.printDebug("InvalidRecords data =>", data.data)
                
                this.doInvalidRecordResponse(data.data)
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },
        
        doInvalidAppliesResponse(data) {
            if ((data == null) || (data.data == null) || (data.data.length < 1)) {
                this.finished = true;
                return
            }

            this.initApplyShowInfo(data.data);
            this.invalidList.push(...data.data)

            this.current++
            this.loading = false;
            
            if (this.invalidList.length >= data.totals) {
                this.printDebug("finish load data")
                this.finished = true;
            }
            
            this.printDebug("invalidList = ", this.invalidList)
        },

        doInvalidRecordResponse(data) {
            if ((data == null) || (data.data == null) || (data.data.length < 1)) {
                this.finished = true;
                return
            }

            this.initRecordShowInfo(data.data);
            this.invalidList.push(...data.data)

            this.current++
            this.loading = false;
            
            if (this.invalidList.length >= data.totals) {
                this.printDebug("finish load data")
                this.finished = true;
            }
            
            this.printDebug("invalidList = ", this.invalidList)
        },
        
        initApplyShowInfo(applies) {
            if (applies != null) {
                applies.forEach((element, index) => {
                    applies[index].employeeInfo = element.employee.name + "  |  " + element.employee.tel

                    applies[index].typeStr = applies[index].type === "INVITE_APPLY" ? "员工邀约" : "访客预约"
                    
                    applies[index].beginDateTime 
                            = applies[index].beginDate + ' ' + applies[index].beginTime.substring(0, 5)

                    applies[index].endDateTime 
                            = applies[index].endDate + ' ' + applies[index].endTime.substring(0, 5)
                    
                    applies[index].relatedStr 
                            = applies[index].related === "VISITOR_DRAFT" ? "申请人" : "陪同人"
                });
            }
        },

        initRecordShowInfo(records) {
            if (records != null) {
                records.forEach((element, index) => {
                    records[index].employeeInfo = element.employee.name + "  |  " + element.employee.tel

                    records[index].typeStr = records[index].apply.type === "INVITE_APPLY" ? "员工邀约" : "访客预约"

                    records[index].createTime = records[index].apply.createTime

                    records[index].relatedStr 
                            = records[index].related === "VISITOR_DRAFT" ? "申请人" : "陪同人"

                    records[index].enterDateTime 
                            = records[index].enterDate + ' ' + records[index].enterTime.substring(0, 5)

                    records[index].leaveDateTime 
                            = records[index].leaveDate == null ? "用户没有签退" :
                                records[index].leaveDate + ' ' + records[index].leaveTime.substring(0, 5)
                });
            }
        }
    },

    filters : {
        fullTimeFilter(value) {
            if (value == null) {
                return
            }

            return value.substring(0, 10) + ' ' + value.substring(11, 16)
        }
    }
}
</script>

<style lang="less" scoped>

.header {
    margin-top: 2px;
    margin-bottom: 10px;
    
    /deep/.van-tab {
        font-size: 18px !important;
    }
    
    /deep/.van-tabs__nav--card {
        border-color: #409EFF;
    }
}

.valid-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 10px 10px 0px 10px;

    border-radius: 15px;
    border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;

    .van-cell {
        padding: 0px 0px 0px 10px;

        /deep/.van-field__label {
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 0px 10px;
        }
    }

    div {
        margin: 0px;
        padding: 0px;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 30px;
    }

    .form-footer {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 20px;

        div {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
        }
    }

    // /deep/.van-field__label {
    //     color: #67C23A;
    // }
}

.divider_history {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin: 0px;
        padding: 0px;
    }
}

.invalid-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #efefef;

    margin: 10px 10px 0px 10px;

    border-radius: 15px;
    // border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;

    .van-cell {
        padding: 0px 0px 0px 10px;

        background-color: #efefef;

        /deep/.van-field__label {
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 0px 10px;
        }
    }

    div {
        margin: 0px;
        padding: 0px;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 30px;
    }

    .form-footer {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 20px;

        div {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
        }
    }

    // /deep/.van-field__label {
    //     color: #67C23A;
    // }
}
    
</style>
