<template>
    <div class="page">
        <van-dialog class="dialog" v-model="showDialog" :before-close="dialogClose"
                    :show-confirm-button="false" :closeOnClickOverlay="true">
            <div class="dialog-header">
                <p>{{ updateTitle }}</p>
            </div>

            <div v-if="this.currentItem.prop === 'tel'">
                <van-field v-model="telUpdateForm.tel" :rules="rules.telRule" center clearable required type="tel"
                           placeholder="新手机号"/>
                <van-field v-model="telUpdateForm.sms" :rules="rules.smsRule" center clearable required type="digit"
                           placeholder="新手机短信验证码">
                    <template #button>
                        <van-button :disabled="verifyBtnDis" size="small" plain type="warning" native-type="button"
                                    @click="getVerifyCode_()">{{ verifyBtnText }}
                        </van-button>
                    </template>
                </van-field>
            </div>

            <div v-else>
                <van-field :placeholder="updateTitle" v-model="updateValue" rows="3" type="textarea" autosize/>
            </div>

            <div class="dialog-footer">
                <van-row>
                    <van-col span="2"></van-col>

                    <van-col span="9">
                        <van-button type="info" size="small" block round native-type="button" @click="cancelModify()">
                            取消
                        </van-button>
                    </van-col>

                    <van-col span="2"></van-col>

                    <van-col span="9">
                        <van-button type="info" size="small" block round native-type="button" @click="submitModify()">
                            确认
                        </van-button>
                    </van-col>
                </van-row>
            </div>
        </van-dialog>

        <van-popup v-model="showVisitorPickerPopup" position="bottom">
            <van-picker show-toolbar :columns="visitorPickerColumns"
                @confirm="onVisitorCertTypePickerConfirm" @cancel="showVisitorPickerPopup = false"/>
        </van-popup>

        <div class="change-header">
            <div v-if="this.accountType === this.ACCOUNT_TYPE_VISITOR">
                <van-uploader :after-read="uploadAvatar" accept="image/*">
                    <van-image round class="header-img" :src="headerUrl" fit="cover" alt="Avatar"/>
                </van-uploader>
                <div class="header-info">点击修改头像</div>
            </div>

            <div v-else>
                <van-image round class="header-img" :src="headerUrl" fit="cover" alt="Avatar"/>
            </div>
        </div>

        <van-form @submit="onSubmit">
            <van-row v-if="this.accountType === this.ACCOUNT_TYPE_VISITOR">
                <div v-for="(item, index) in visitorFormLabel" :key="index">
                    <van-field v-if="item.show === true" v-model="form[item.prop]" :required="item.required" :rules="item.rule"
                            :name="item.label" :label="item.label" :placeholder="'请输入 ' + item.label" colon is-link
                            :disabled="item.prop === 'tel'" @click="fieldClick(item)">
                    </van-field>
                </div>
            </van-row>

            <van-row v-else>
                <div v-for="(item, index) in employeeLabel" :key="index">
                    <van-field readonly v-model="form[item.prop]" :label="item.label"
                               :placeholder="'请输入' + item.label"/>
                </div>
            </van-row>

            <van-row>
                <van-col span="20" offset="2">
                    <van-button style="margin-top: 10px;" round type="info" native-type="submit" block
                                size="small">返回首页
                    </van-button>
                </van-col>
            </van-row>
        </van-form>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {visitorModify, checkEmailUpdate} from '@/api/visitor-api'
import {employeeUpdate} from '@/api/employee-api'
import {getVerifyCode, checkVerifyCode} from '@/api/sms-api'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

import {getDownloadUrl, uploadBase64File} from "@/api/local-oss-api"

export default {
    mixins: [visitor_info_mixin_table],

    mounted() {
        if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
            (async () => {
                let setting = await getVisitorSettings();

                let result = this.verifyVisitorInfo(this.visitor, setting);
                
                this.printDebug("setting = ", setting);

                this.form.id = this.visitor.id
                this.form.head = this.visitor.head
                
                if (result) {
                    this.flag = "modify"

                    this.setShowVisitorLabel(this.visitor, setting)
                    
                    this.visitorFormLabel.forEach(item => {
                        if ((item.show === true)&&(item.prop !== "cert_type")) {
                            this.form[item.prop] = this.visitor[item.prop]
                        }
                    })
                } else {
                    this.flag = "login"
                    
                    this.setBookVisitorLabel(setting)
                    
                    this.visitorFormLabel.forEach(item => {
                        if ((item.show === true)&&(item.prop !== "cert_type")) {
                            this.form[item.prop] = this.visitor[item.prop]
                        }
                    })
                }
            })();
        } else if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
            this.form.id = this.employee.id
            this.form.tel = this.employee.tel
            this.form.name = this.employee.name
            this.form.head = this.employee.head
            this.form.workId = this.employee.workId
            this.form.department = this.employee.departmentName
            this.form.title = this.employee.title
            this.form.age = this.employee.age
        }

        if ((this.accountType === this.ACCOUNT_TYPE_VISITOR)&&(this.visitor.head !== null)) {
            this.headerUrl = getDownloadUrl() + this.visitor.head;
            this.printDebug("headerUrl => ", this.headerUrl)
        } else if ((this.accountType === this.ACCOUNT_TYPE_EMPLOYEE)&&(this.employee.head !== null)) {
            if (this.employee.head.startsWith("http")) {
                this.headerUrl = this.employee.head;
            } else {
                this.headerUrl = getDownloadUrl() + this.employee.head;
            }
        }
    },

    data() {
        return {
            //用来区分是首次登录完善信息，还是后续修改信息。
            flag: null,

            headerUrl: require('../../assets/header.jpg'),

            form: {
                tel: null,
                name: null,
                company: null,
                address: null,
                email: null
            },

            verifyBtnDis: false,
            verifyBtnText: "获取验证码",
            intervalId: null,

            employeeLabel: [
                {
                    prop: 'name',
                    label: '员工姓名'
                },
                {
                    prop: 'tel',
                    label: '手机号码'
                },
                {
                    prop: 'department',
                    label: '所在部门'
                },
                {
                    prop: 'workId',
                    label: '员工工号'
                }
                
            ],

            currentItem: {},

            showDialog: false,
            updateTitle: null,
            updateValue: null,

            telUpdateForm: {
                tel: null,
                sms: null
            },

            rules: {
                telRule: [
                    {required: true, message: '请填写正确的手机号码！', tigger: 'onBlur'},
                    {pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
                ],
                smsRule: [
                    {required: false, message: '请填写正确的验证码！'},
                    {pattern: /\d{6}$/, message: '验证码输入错误！'}
                ]
            }
        }
    },

    computed: {
        ...mapState(['accountType', 'employee', 'visitor'])
    },

    methods: {
        ...mapMutations(['setEmployee', 'setVisitor']),

        resetVerifyBtn() {
            clearInterval(this.intervalId)
            this.verifyBtnDis = false
            this.verifyBtnText = "获取验证码";
        },

        forbidVerifyBtn() {
            this.verifyBtnDis = true
            this.verifyBtnText = 120;

            let count = 119;

            this.intervalId = setInterval(() => {
                this.verifyBtnText = count;
                count--;

                if (count < 0) {
                    this.resetVerifyBtn()
                }
            }, 1000);
        },

        getVerifyCode_() {
            this.forbidVerifyBtn();

            getVerifyCode(this.telUpdateForm.tel, "信息修改").then(({data}) => {
                console.log("data => ", data)
                if (data.code !== 200) {
                    this.resetVerifyBtn()
                    this.$toast.fail(this.SERVER_ERROR_MSG)
                }
            }).catch((error) => {
                this.resetVerifyBtn()
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        },

        uploadAvatar(file) {
            // 文件名
            let objectKey = null

            if (file.file.size > 1024*1024) {
                this.$toast.fail("文件超过1M")
                return
            }
            
            const fileType = file.content.split(';').shift().split(':').pop();
            const fileName = this.form.id + '_' + new Date().getTime();
            
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                objectKey = `mobile/visitor/${fileName}.${fileType.split('/').pop()}`;
            } else {
                objectKey = `mobile/employee/${fileName}.${fileType.split('/').pop()}`;
            }

            this.printDebug("file.content => ", file.content)
            
            uploadBase64File(file.content, objectKey).then(({data}) => {
                if (data.code !== 200) {
                    this.$toast.fail("上传失败")
                    return
                }

                let filePath = data.data

                let headForm = {
                    head: filePath
                }

                if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                    visitorModify(headForm).then(({data}) => {
                        this.printDebug("update data => ", data)

                        this.headerUrl = getDownloadUrl() + filePath
                        this.visitor.head = filePath
                    }).catch(_ => {
                        this.$toast.fail(this.SERVER_ERROR_MSG)
                    })
                } else {
                    employeeUpdate(headForm).then(({data}) => {
                        this.printDebug("update data => ", data)

                        this.headerUrl = getDownloadUrl() + filePath
                        this.employee.head = filePath
                    }).catch(_ => {
                        this.$toast.fail(this.SERVER_ERROR_MSG)
                    })
                }
            })
        },

        onSubmit() {
            if (this.flag === "login") {
                this.$toast.success("登录成功")
            }
            this.$router.push({name: "home"})
        },

        fieldClick(item) {
            if (item.prop === "tel") {
                return;
            }
            
            if (item.prop === "cert_type") {
                document.activeElement.blur()

                this.visitorFieldValue = item

                this.visitorPickerColumns = ["护照", "身份证"]
                
                this.showVisitorPickerPopup = true
            } else {
                this.showDialog = true

                this.currentItem = item
                this.updateTitle = "请输入 " + "\"" + item.label + "\""

                //
                this.telUpdateForm = {}
                this.updateValue = ""
            }
        },

        onVisitorCertTypePickerConfirm(value) {
            this.onBookVisitorPickerConfirm(value, this.form)
        },

        cancelModify() {
            this.showDialog = false
            this.currentItem = {}
        },

        submitModify() {
            if (this.currentItem.prop === "tel") {
                checkVerifyCode(this.telUpdateForm.tel, this.telUpdateForm.sms).then(({data}) => {
                    if (data.code === 200) {
                        this.modifyTelephone()
                    } else {
                        this.$toast.fail(this.VERIFY_CODE_ERROR_MSG)
                    }
                }).catch((error) => {
                    this.$toast.fail(this.NETWORK_ERROR_MSG)
                })
            } else {
                (async () => {
                    if ((this.updateValue == null) || (this.updateValue.trim() === "")) {
                        this.$toast.fail("请输入要更新的内容")
                        return
                    }

                    if ((this.currentItem.prop === "ic")&&(!this.icCardRule.test(this.updateValue))) {
                        this.$toast.fail("身份证格式错误！")
                        return
                    }

                    if (this.currentItem.prop === "email") {
                        let update = (await checkEmailUpdate(this.updateValue)).data;
                        
                        if (update.code !== 200) {
                            this.$toast.fail("邮箱已被占用")
                            return
                        }
                    }

                    this.modifyCommon()
                })();
            }
        },

        modifyTelephone() {
            console.log("this.telUpdateForm => ", this.telUpdateForm)

            let updateObj = {}

            updateObj[this.currentItem.prop] = this.telUpdateForm.tel

            console.log("updateObj => ", updateObj)

            visitorModify(updateObj).then(({data}) => {
                // console.log("update data => ", data)
                this.form[this.currentItem.prop] = this.telUpdateForm.tel

                this.modifyLocalData(this.telUpdateForm.tel)

                this.showDialog = false
                this.currentItem = {}
            }).catch(_ => {
                this.$toast.fail(this.SERVER_ERROR_MSG)
            })
        },

        modifyCommon() {
            let updateObj = {}

            updateObj[this.currentItem.prop] = this.updateValue

            console.log("updateObj => ", updateObj)

            visitorModify(updateObj).then(({data}) => {
                console.log("update data => ", data)
                this.form[this.currentItem.prop] = this.updateValue

                this.modifyLocalData(this.updateValue)

                this.showDialog = false
                this.currentItem = {}
            }).catch(_ => {
                this.$toast.fail(this.SERVER_ERROR_MSG)
            })
        },

        dialogClose(action, done) {
            this.currentItem = {}
            done()
        },

        modifyLocalData(value) {
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                this.visitor[this.currentItem.prop] = value
            } else {
                this.employee[this.currentItem.prop] = value
            }
        }
    },
}
</script>

<style lang="less" scoped>
.dialog {
    .dialog-header {
        display: flex;
        justify-content: center;
        color: green;
    }

    .dialog-footer {
        margin-bottom: 10px;
    }
}

.change-header {
    padding-top: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header-img {
        height: 80px;
        width: 80px;
    }

    .header-info {
        font-size: small;
        margin-top: 8px;
    }
}

</style>
