<template>
    <div>
        <div class="header">
            <van-tabs type="card" v-model="tab_index" @click="onTabClick">
                <van-tab title="审批中"></van-tab>
                <van-tab title="已审批"></van-tab>
                <van-tab title="已到访"></van-tab>
            </van-tabs>
        </div>
        
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <div v-if="validDataStatus">
                <van-form class="valid-form" v-for="(item, index) in validList" :key="index">
                    <div>
                        <div class="form-header">
                            <div style="font-size: 14px !important; color: #303133; font-weight: 700;">
                                {{item.visitorInfo}}
                            </div>
                            
                            <div style="font-size: 14px !important; color: gray;">
                                {{item.createTime | fullTimeFilter()}}
                            </div>
                        </div>
                        
                        <van-field colon readonly v-model="item.typeStr" label="申请类型"/>

                        <div v-if="tab_index === 2">
                            <van-field colon readonly v-model="item.enterDateTime" label="签到时间"/>
                            <van-field colon readonly v-model="item.leaveDateTime" label="签离时间"/>
                        </div>

                        <div v-else>
                            <van-field colon readonly v-model="item.beginDateTime" label="开始时间"/>
                            <van-field colon readonly v-model="item.endDateTime" label="结束时间"/>
                        </div>
                        
                        <div class="form-footer">
                            <div class="related">
                                <div v-for="name in item.relatedList" :key="name">
                                    <van-tag size="mini" plain round type="danger" style="margin-right: 5px;">
                                        {{ name }}
                                    </van-tag>
                                </div>
                            </div>
                            
                            <div>
                                <van-button v-if="showApproveButton(item)" @click="approve(item)" 
                                            plain size="small" type="info" style="margin-right:10px">
                                    审批
                                </van-button>

                                <van-button v-if="showTransferButton(item)" @click="transfer(item)" 
                                            plain size="small" type="info" style="margin-right:10px">
                                    转签
                                </van-button>

                                <van-button v-if="showCheckoutButton(item)" @click="checkOut(item)" 
                                            plain size="small" type="info" style="margin-right:10px">
                                    {{ checkoutButtonText(item) }}
                                </van-button>
                                    
                                <van-button plain size="small" type="info" @click="detail(item)">
                                    查看
                                </van-button>
                            </div>
                        </div>
                        
                        <div v-if="showRemindMessage(item)" class="form-bootom">
                            <div>等待  {{ item.currentEmployeeInfo.name }}  审批</div>
                        </div>
                    </div>
                </van-form>
            </div>

            <div v-else style="text-align:center; height: 60px; line-height: 60px; color: gray">
                没有待处理任务
            </div>
            
            <van-divider dashed :style="{color: '#909399', borderColor: '#909399', padding: '20px'}" 
                            @click="showHistoryData()">
                <div v-if="showHistory === false" class="divider_history">
                    <p style="color:#F56C6C">加载过期记录</p>

                    <van-image style="width:30px; height:30px" fit="cover" alt="Avatar"
                                :src="require('../../assets/select.png')"/>
                </div>

                <div v-else class="divider_history">
                    <p style="color:#F56C6C">过期记录</p>

                    <van-image style="width:20px; height:25px;" fit="cover" alt="Avatar"
                                :src="require('../../assets/selected.png')"/>
                </div>
            </van-divider>
            
            <van-list v-if="showHistory === true" v-model="loading" @load="loadHistory()"
                      :finished="finished" finished-text="没有更多了"
                      :error.sync="error" error-text="请求失败，点击重新加载">
                <van-form class="invalid-form" v-for="(item, index) in invalidList" :key="index">
                    <div>
                        <div class="form-header">
                            <div style="font-size: 14px !important; color: gray; font-weight: 700;">
                                {{item.visitorInfo}}
                            </div>
                            
                            <div style="font-size: 14px !important; color: gray;">
                                {{item.createTime | fullTimeFilter()}}
                            </div>
                        </div>
                        
                        <van-field colon readonly v-model="item.typeStr" label="申请类型"/>

                        <div v-if="tab_index === 2">
                            <van-field colon readonly v-model="item.enterDateTime" label="签到时间"/>
                            <van-field colon readonly v-model="item.leaveDateTime" label="签离时间"/>
                        </div>

                        <div v-else>
                            <van-field colon readonly v-model="item.beginDateTime" label="开始时间"/>
                            <van-field colon readonly v-model="item.endDateTime" label="结束时间"/>
                        </div>
                        
                        <div class="form-footer">
                            <div class="related">
                                <div v-for="name in item.relatedList" :key="name">
                                    <van-tag size="mini" plain round style="margin-right: 5px;">
                                        {{ name }}
                                    </van-tag>
                                </div>
                            </div>
                            
                            <div>
                                <van-button plain size="small" @click="detail(item)">
                                    查看
                                </van-button>
                            </div>
                        </div>
                    </div>
                </van-form>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import {getEmployeeByBatch} from "@/api/employee-api"

import { getValidPendingAppliesByEmployeeId, getInvalidPendingAppliesByEmployeeId,
    getValidFinishAppliesByEmployeeId, getInvalidFinishAppliesByEmployeeId} from "@/api/apply-api"

import {getValidRecordsByEmployeeId, getInvalidRecordsByEmployeeId} from "@/api/record-api"

export default {
    mounted() {
        this.routeToTab(this.$route.params.active)
    },

    data() {
        return {
            validList: [],
            invalidList: [],
            
            showHistory: false,
            
            tab_index: 0,
            active_tab: null,
            
            //---下面是下拉刷新---
            refreshing: false,

            //---下面是加载更多历史数据---
            loading: false,
            finished: true,
            error: false,

            //-------下面是分页参数--------
            // 当前页码值
            current: 1,
            // 每页显示数
            size: 5
        }
    },

    computed: {
        ...mapState(['employee']),

        validDataStatus() {
            if ((this.validList == null) || (this.validList.length <= 0)) {
                return false
            }

            return true
        }
    },

    methods: {
        showApproveButton(item) {
            if ((this.tab_index === 0) && (item.currentEmployeeId === this.employee.id)) {
                return true
            }

            return false
        },

        showTransferButton(item) {
            if (this.tab_index !== 1) {
                return false
            }
            
            if ((item.transferEmployeeId == null) && (item.employeeId === this.employee.id)) {
                return true
            }

            if (item.transferEmployeeId === this.employee.id) {
                return true
            }
            
            return false
        },

        showCheckoutButton(item) {
            if (this.tab_index !== 2) {
                return false
            }

            if (item.checkoutTime == null) {
                return false
            }

            if ((item.apply.transferEmployeeId == null) && (item.employeeId === this.employee.id)) {
                return true
            }

            if (item.apply.transferEmployeeId === this.employee.id) {
                return true
            }

            return false
        },

        checkoutButtonText(item) {
            if (item.confirmTime == null) {
                return "签退"
            }
            
            let now = new Date().getTime()
            let confirmTime = new Date(item.confirmTime).getTime()
            let checkoutTime = item.checkoutTime * 60 * 1000
            
            if (now > confirmTime + checkoutTime) {
                return "加签"
            }

            return "签退中..."
        },

        showRemindMessage(item) {
            if ((this.tab_index === 0) && (item.currentEmployeeId !== this.employee.id)) {
                return true
            }

            return false
        },

        approve(item) {
            this.$router.push({ name: "approve", 
                                params: {
                                    source: 'employeerecords', 
                                    type: this.RECORD_OPS_APPROVE, 
                                    active: this.active_tab, 
                                    data: item} })
        },

        transfer(item) {
            this.$router.push({ name: "transfer", 
                                params: {
                                    source: 'employeerecords', 
                                    type: this.RECORD_OPS_TRANSFER, 
                                    active: this.active_tab, 
                                    data: item} })
        },

        checkOut(item) {
            this.$router.push({ name: "checkout", 
                                params: {
                                    source: 'employeerecords', 
                                    type: this.RECORD_OPS_CHECKOUT, 
                                    active: this.active_tab, 
                                    data: item} })
        },

        detail(item) {
            this.$router.push({ name: "recorddetail",
                                params: {
                                    source: 'employeerecords',
                                    type: this.RECORD_OPS_DETAIL,
                                    active: this.active_tab,
                                    data: item} })
        },

        routeToTab(state) {
            switch(state) {
                case this.RECORD_TAB_PROCESS:
                    this.onTabClick(0)
                    break;
                case this.RECORD_TAB_FINISH:
                    this.onTabClick(1)
                    break;
                case this.RECORD_TAB_ENTER:
                    this.onTabClick(2)
                    break;
                default:
                    this.onTabClick(0)
            }
        },

        onTabClick(index, title) {
            this.validList = []
            this.invalidList = []

            this.showHistory = false

            this.loading = false
            this.finished = true
            this.error = false

            this.current = 1
            this.size = 5

            if (index === 0) {
                this.tab_index = 0
                this.active_tab = this.RECORD_TAB_PROCESS

                this.getValidPendingData()
            } else if (index === 1) {
                this.tab_index = 1
                this.active_tab = this.RECORD_TAB_FINISH

                this.getValidFinishData()
            } else {
                this.tab_index = 2
                this.active_tab = this.RECORD_TAB_ENTER

                this.getValidRecordsData()
            }
        },

        showHistoryData() {
            if (this.showHistory === false) {
                this.loading = false
                this.finished = false
                this.error = false
            } else {
                this.loading = false
                this.finished = true
                this.error = false
            }

            this.showHistory = !this.showHistory
            this.current = 1
            this.size = 5
            this.invalidList = []
        },

        onRefresh() {
            this.onTabClick(this.tab_index, null)
            this.refreshing = false
        },

        loadHistory() {
            console.log("员工触发loading，加载更多历史数据")
            
            if (this.tab_index === 0) {
                this.getInvalidPendingData()
            } else if (this.tab_index === 1) {
                this.getInvalidFinishData()
            } else {
                this.getInvalidRecordsData()
            }
        },
        
        getValidPendingData() {
            let tempValidList = [];

            (async () => {
                let data = (await getValidPendingAppliesByEmployeeId()).data;

                this.printDebug("PendingApplies data =>", data)

                if ((data.code !== 200) || (data.data == null)) {
                    return
                }

                //
                tempValidList = data.data;
                this.initApplyShowInfo(tempValidList);

                //获取非当前员工信息，用在每个卡片的最底部的提示信息
                let codes = [];
                tempValidList.forEach(item => {
                    if (this.employee.id !== item.currentEmployeeId) {
                        codes.push(item.currentEmployeeCode)
                    }
                })

                if (codes.length >= 1) {
                    let employees = (await getEmployeeByBatch(codes)).data.data;
                    this.printDebug("employees => ", employees);

                    tempValidList.forEach(item => {
                        if (this.employee.id !== item.currentEmployeeId) {
                            for (let i = 0; i < employees.length; i++) {
                                if (employees[i].id === item.currentEmployeeId) {
                                    item.currentEmployeeInfo = employees[i]
                                    break
                                }
                            }
                        }
                    })
                }
                
                //处理后的信息赋值给显示列表
                this.validList = tempValidList
                this.printDebug("validList = ", this.validList)
            })();
        },
        
        getInvalidPendingData() {
            let page = {
                "current" : this.current, 
                "size" : this.size
            }

            this.printDebug("load page = ", this.current)
            
            getInvalidPendingAppliesByEmployeeId(page).then(({data}) => {
                this.printDebug("InvalidPendingApplies data =>", data.data)
                
                this.doInvalidAppliesResponse(data.data)
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getValidFinishData() {
            getValidFinishAppliesByEmployeeId().then(({data}) => {
                this.printDebug("FinishApplies data =>", data.data)
                
                this.validList = data.data

                this.initApplyShowInfo(this.validList);
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getInvalidFinishData() {
            let page = {
                "current" : this.current, 
                "size" : this.size
            }

            this.printDebug("load page = ", this.current)

            getInvalidFinishAppliesByEmployeeId(page).then(({data}) => {
                this.printDebug("InvalidFinishApplies data =>", data.data)
                
                this.doInvalidAppliesResponse(data.data)
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getValidRecordsData() {
            getValidRecordsByEmployeeId().then(({data}) => {
                this.printDebug("ValidRecords data =>", data.data)
                
                this.validList = data.data

                this.printDebug("this.validList =>", this.validList)

                this.initRecordShowInfo(this.validList);
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },

        getInvalidRecordsData() {
            let page = {
                "current" : this.current, 
                "size" : this.size
            }

            this.printDebug("load page = ", this.current)

            getInvalidRecordsByEmployeeId(page).then(({data}) => {
                this.printDebug("InvalidRecords data =>", data.data)
                
                this.doInvalidRecordResponse(data.data)
            }).catch((error) => {
                this.printDebug("error =>", error)
            })
        },
        
        doInvalidAppliesResponse(data) {
            if ((data == null) || (data.data == null) || (data.data.length < 1)) {
                this.finished = true;
                return
            }

            this.initApplyShowInfo(data.data);
            this.invalidList.push(...data.data)

            this.current++
            this.loading = false;
            
            if (this.invalidList.length >= data.totals) {
                this.printDebug("finish load data")
                this.finished = true;
            }
            
            this.printDebug("invalidList = ", this.invalidList)
        },

        doInvalidRecordResponse(data) {
            if ((data == null) || (data.data == null) || (data.data.length < 1)) {
                this.finished = true;
                return
            }

            this.initRecordShowInfo(data.data);
            this.invalidList.push(...data.data)

            this.current++
            this.loading = false;
            
            if (this.invalidList.length >= data.totals) {
                this.printDebug("finish load data")
                this.finished = true;
            }
            
            this.printDebug("invalidList = ", this.invalidList)
        },
        
        initApplyShowInfo(applies) {
            if (applies != null) {
                applies.forEach((element, index) => {
                    applies[index].visitorInfo = element.visitor.name + "  |  " + element.visitor.tel

                    applies[index].typeStr = applies[index].type === "INVITE_APPLY" ? "员工邀约" : "访客预约"

                    applies[index].beginDateTime 
                            = applies[index].beginDate + ' ' + applies[index].beginTime.substring(0, 5)

                    applies[index].endDateTime 
                            = applies[index].endDate + ' ' + applies[index].endTime.substring(0, 5)
                            
                    //员工和申请之间关系
                    applies[index].relatedList = []

                    let relatedList = applies[index].related.split("|")
                    
                    relatedList.forEach(item => {
                        switch(item) {
                            case "EMPLOYEE_VISITED":
                                applies[index].relatedList.push("被访者")
                                break;
                            case "EMPLOYEE_APPROVED":
                                applies[index].relatedList.push("审核者")
                                break;
                            case "EMPLOYEE_MAKECOPY":
                                applies[index].relatedList.push("抄送者")
                                break;
                            case "EMPLOYEE_TRANSFER":
                                applies[index].relatedList.push("转签者")
                                break;
                            default:
                                break;
                        }
                    })

                    this.printDebug("relatedList = ", applies[index].relatedList)
                });
            }
        },

        initRecordShowInfo(records) {
            if (records != null) {
                records.forEach((element, index) => {
                    records[index].visitorInfo = element.visitor.name + "  |  " + element.visitor.tel

                    records[index].typeStr = records[index].apply.type === "INVITE_APPLY" ? "员工邀约" : "访客预约"

                    records[index].createTime 
                            = records[index].apply.createTime.substring(0, 10) + ' ' + records[index].apply.createTime.substring(11, 16)

                    records[index].enterDateTime 
                            = records[index].enterDate + ' ' + records[index].enterTime.substring(0, 5)

                    records[index].leaveDateTime 
                            = records[index].leaveDate == null ? "用户没有签退" :
                                records[index].leaveDate + ' ' + records[index].leaveTime.substring(0, 5)
                    
                    //员工和记录之间关系
                    records[index].relatedList = []

                    let relatedList = records[index].related.split("|")
                    
                    relatedList.forEach(item => {
                        switch(item) {
                            case "EMPLOYEE_VISITED":
                                records[index].relatedList.push("被访者")
                                break;
                            case "EMPLOYEE_APPROVED":
                                records[index].relatedList.push("审核者")
                                break;
                            case "EMPLOYEE_MAKECOPY":
                                records[index].relatedList.push("抄送者")
                                break;
                            case "EMPLOYEE_TRANSFER":
                                records[index].relatedList.push("转签者")
                                break;
                            default:
                                break;
                        }
                    })

                    this.printDebug("relatedList = ", records[index].relatedList)
                });
            }
        },
    },

    filters : {
        fullTimeFilter(value) {
            if (value == null) {
                return
            }

            return value.substring(0, 10) + ' ' + value.substring(11, 16)
        }
    }
}
</script>

<style lang="less" scoped>

.header {
    margin-top: 2px;
    margin-bottom: 10px;
    
    /deep/.van-tab {
        font-size: 18px !important;
    }
    
    /deep/.van-tabs__nav--card {
        border-color: #409EFF;
    }
}

.valid-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 10px 10px 0px 10px;

    border-radius: 15px;
    border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;

    .van-cell {
        padding: 0px 0px 0px 10px;

        /deep/.van-field__label {
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 0px 10px;
        }
    }

    div {
        margin: 0px;
        padding: 0px;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 30px;
    }

    .form-footer {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 20px;

        div {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
        }

        .related {
            display: flex;
            justify-content: flex-start;
        }
    }

    .form-bootom {
        display: flex;
        justify-content: center;
        margin: 20px 20px 10px 20px;
        padding: 5px 0px 5px 0px;
        margin-bottom: 10px;
        // background: #efefef;
        border-radius: 15px;
        // border:0.5px dashed rgb(81, 126, 230);
        border:0.5px dashed rgb(222, 231, 248);
        box-shadow: 0 0 15px #efefef;
        // color: #67C23A;
    }

    // /deep/.van-field__label {
    //     color: #67C23A;
    // }
}

.divider_history {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin: 0px;
        padding: 0px;
    }
}

.invalid-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #efefef;

    margin: 10px 10px 0px 10px;

    border-radius: 15px;
    // border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;

    .van-cell {
        padding: 0px 0px 0px 10px;

        background-color: #efefef;

        /deep/.van-field__label {
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 0px 10px;
        }
    }

    div {
        margin: 0px;
        padding: 0px;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 30px;
    }

    .form-footer {
        display: flex;
        justify-content: space-between;
        
        margin: 10px 20px 10px 20px;

        .related {
            display: flex;
            justify-content: flex-start;
        }
    }

    // /deep/.van-field__label {
    //     color: #67C23A;
    // }
}

.van-tag {
    height: 32px;
}

</style>
