<template>
    <div>
        <van-dialog class="ntc-dialog" v-model="showVisitorNotice"  title="访客须知"
                style="overflow-y:auto" :show-confirm-button="false" :closeOnClickOverlay="true">
            <div class="main" v-html="visitorNotice"/>
            
            <van-row class="bottom-button">
                <van-col span="4"></van-col>

                <van-col span="16">
                    <van-button size="small" type="warning" round block @click="showVisitorNotice=false">确 认</van-button>
                </van-col>

                <van-col span="4"></van-col>
            </van-row>
        </van-dialog>

        <van-dialog class="dialog" v-model="showDialog" :show-confirm-button="false" :closeOnClickOverlay="true">
			<van-form @submit="submitAccompany">
                <div class="dialog-header">
                    添加同行人
                </div>
                
                <div v-for="(item, index) in visitorFormLabel" :key="index">
                    <van-field v-if="item.show === true" v-model="accompanyForm[item.prop]" :required="item.required" :rules="item.rule"
                            :name="item.label" :label="item.label" :placeholder="'请输入 ' + item.label" colon
                            @click="visitorFormFieldClick(item)">
                    </van-field>
                </div>
                
                <van-row class="dialog-footer">
					<van-col span="2"></van-col>
					<van-col span="9">
						<van-button type="info" size="small" block round native-type="button" @click="cancelAccompany()">取消</van-button>
					</van-col>
					<van-col span="2"></van-col>
					<van-col span="9">
						<van-button type="info" size="small" block round native-type="submit">确认</van-button>
					</van-col>
				</van-row>
            </van-form>
		</van-dialog>

        <van-popup v-model="showDatePopup" position="bottom">
            <van-datetime-picker v-model="currentDate" type="datetime"
                @confirm="onDateConfirm" @cancel="showDatePopup = false"
                :formatter="formatter" :filter="filter" :min-date="minDate" :max-date="maxDate"/>
        </van-popup>
        
        <van-popup v-model="showPickerPopup" position="bottom">
            <van-picker show-toolbar :columns="columns"
                @confirm="onPickerConfirm" @cancel="showPickerPopup = false"/>
        </van-popup>

        <van-popup v-model="showAreaPopup" position="bottom">
            <van-picker show-toolbar :columns="columns"
                @confirm="onAreaConfirm" @cancel="showAreaPopup = false"/>
        </van-popup>

        <van-popup v-model="showVisitorPickerPopup" position="bottom">
            <van-picker show-toolbar :columns="visitorPickerColumns"
                @confirm="onVisitorPickerConfirm" @cancel="showVisitorPickerPopup = false"/>
        </van-popup>

        <van-popup class="carKeyboardContainer" v-model="showCarKeyboardPopup" position="bottom">
            <CarKeyboard :onConfirm="onCarKeyboardConfirm" :onCancel="onCarKeyboardCancel"/>
        </van-popup>
        
        <div class="manage">
            <van-row class="title">
                <van-col span="6">
                    <div class="title_left" @click="addPeople">
                        <van-image round style="width:80px; height: 80px; background: #efefef" 
                                    :src="require('../../assets/plus.png')"/>
                        <p style="color: #E6A23C; font-size: 14px">{{titleDesc}}</p>
                    </div>
                </van-col>
                
                <van-col span="17" v-if="this.accountType == this.ACCOUNT_TYPE_VISITOR">
                    <div v-for="(item, index) in employeeFormLabel" :key="index">
                        <van-field :name="item.label" :label="item.label" colon :readonly="true"
                                    v-model="form[item.prop]"/>
                    </div>
                </van-col>

                <van-col v-else span="17">
                    <div v-for="(item, index) in visitorFormLabel" :key="index">
                        <van-field v-if="item.prop === 'name' || item.prop === 'tel' || item.prop === 'company'" 
                                    v-model="form[item.prop]" :name="item.label" :label="item.label" colon :readonly="true"/>
                    </div>
                </van-col>

                <van-col span="1"></van-col>
            </van-row>
        </div>
        
        <van-form @submit="submitApply">
            <div class="apply">
                <div class="top"></div>

                <div v-if="areaVerifyFlg">
                    <van-field v-model="parentArea.value"
                        @click="areaFieldClick('parent')" @focus="forbidFocus" :columns="columns"
                        :rules="[{required: true}]" required is-link input-align="right" 
                        name="到访区域" label="到访区域" placeholder="请输入到访区域"/>

                    <van-field v-model="subArea.value"
                        @click="areaFieldClick('sub')" @focus="forbidFocus" :columns="columns"
                        :rules="[{required: true}]" required is-link input-align="right" 
                        name="到访地址" label="到访地址" placeholder="请输入到访地址"/>
                </div>
                
                <div v-for="(item, index) in applyForm" :key="index">
                    <van-field v-if="item.realType == 'beginTime'" v-model="applyBeginTime"
                        @click="applyTimeFieldClick('begin')" 
                        :rules="rules.applyBeginTime"
                        required is-link input-align="right" @focus="forbidFocus"
                        name="开始时间" label="开始时间" placeholder="请输入开始时间"/>

                    <van-field v-else-if="item.realType == 'endTime'" v-model="applyEndTime"
                        @click="applyTimeFieldClick('end')"
                        :rules="rules.applyEndTime"
                        required is-link input-align="right" @focus="forbidFocus"
                        name="结束时间" label="结束时间" placeholder="请输入结束时间"/>

                    <van-field v-else-if="item.realType == 'picker'" v-model="item.value"
                        @click="fieldClick(item)" @focus="forbidFocus" :columns="columns"
                        :rules="item.rule"
                        :required="item.required == 1" :is-link="item.realType != 'input'"
                        input-align="right" :name="item.title" :label="item.title" :placeholder="'请输入' + item.title"/>
                        
                    <van-field v-else-if="item.realType != 'accompany'" v-model="item.value" 
                        @click="fieldClick(item)"
                        :rules="item.rule"
                        :required="item.required == 1" :is-link="item.realType != 'input'"
                        input-align="right" :name="item.title" :label="item.title" :placeholder="'请输入' + item.title"/>
                </div>

                <div class="footer"></div>
            </div>
            
            <div v-if="accompanyFlage" class="accompany">
                <div class="title">
                    添 加 同 行 人
                </div>
                
                <van-row style="margin-bottom: 20px">
                    <div v-if="this.accountType === this.ACCOUNT_TYPE_EMPLOYEE">
                        <!-- <van-col offset="1" span="10">
                            <van-button size="mini" round block type="info" native-type="button" @click="addAccompany()"> 手动添加 </van-button>
                        </van-col>

                        <van-col offset="2" span="10">
                            <van-button size="mini" round block type="info" native-type="button" @click="addUsualAccompany()"> 常邀访客 </van-button>
                        </van-col> -->

                        <van-col offset="3" span="18">
                            <van-button size="mini" round block type="info" native-type="button" @click="addUsualAccompany()"> + 添 加 同 行 人 </van-button>
                        </van-col>
                    </div>

                    <div v-else>
                        <van-col offset="3" span="18">
                            <van-button size="mini" round block type="info" native-type="button" @click="addAccompany()"> + 添 加 同 行 人 </van-button>
                        </van-col>
                    </div>
                </van-row>
                
                <div class="accompany-list">
                    <van-badge v-for="(item, index) in accompanyList" :key="index" 
                                style="margin-right: 15px; margin-bottom: 15px;">
                        <van-tag size="normal" plain type="warning">
                            {{ item.name }} | {{ item.tel }} | {{ item.company }}
                        </van-tag>
                        <template #content>
                            <van-icon name="cross" class="badge-icon" @click="deleteAccompany(item)"/>
                        </template>
                    </van-badge>
                </div>
            </div>
            
            <van-col offset="2" span="20" style="margin-bottom: 10px;">
                <van-button size="small" round block type="primary" native-type="submit" :loading=isLoading>提 交</van-button>
            </van-col>
        </van-form>
    </div>
</template>

<script>
import CarKeyboard from '@/components/carkeyboard/CarKeyboard.vue'

import { getVisitorSettings } from '@/api/setting-api'
import { visitor_info_mixin_table } from '@/utils/visitor-info-mixin'

import { form_parse_mixin_table } from './form-parse-mixin'

import { mapState, mapMutations } from 'vuex'
import { addApply, getForm } from '@/api/apply-api'
import { getAreaList } from '@/api/area-api'
import { addCompanyVisitor } from '@/api/visitor-api'
import { getVisitorNotice } from '@/api/setting-api'

export default {
    mixins: [form_parse_mixin_table, visitor_info_mixin_table],
    
    mounted() {
        this.printDebug("this.$route.params => ", this.$route.params);

        let routeValue = this.$route.params.value;
        let type = this.$route.params.type
        let submit_info = this.$route.params.submit_info
        let extern_info = this.$route.params.extern_info

        if (submit_info == null) {
            (async () => {
                this.parentArea.valueList = []

                this.areas = (await getAreaList()).data.data
                
                this.areas.forEach(parent => {
                    //父区域
                    this.parentArea.valueList.push(parent.name)
                    
                    //检查是否开启区域验证
                    if (!this.areaVerifyFlg) {
                        parent.areas.forEach(item => {
                            if (item.verify === 1) {
                                this.areaVerifyFlg = true
                            }
                        })
                    }
                })
            })();
        }
        
        if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
            (async () => {
                let data = (await getForm(this.APPLY_TYPE_INVITE)).data;

                if ((data.code === 200)&&(data.data != null)&&(data.data.formItems != null)) {
                    this.applyForm = this.parseFormType(data.data.formItems);
                    this.addFormVerifyRules();
                }

                this.printDebug("applyForm => ", this.applyForm);

                if (routeValue != null) {
                    if (type === "draft") {
                        this.form.id = routeValue.id
                        this.form.code = routeValue.code
                        this.form.name = routeValue.name
                        this.form.tel = routeValue.tel
                        this.form.company = routeValue.company
                    }
                    
                    this.resetFields(submit_info)
                    
                    if (this.applyForm != null) {
                        this.applyForm.forEach(item => {
                            if ((item.type === 'company')&&(item.value === "")) {
                                item.value = this.form.company
                            }
                        })
                    }

                    if ((type === "accompany")&&(extern_info !== null)) {
                        let draft = extern_info.draft
                        let accompanyList = extern_info.accompanyList

                        if (draft != null) {
                            this.form.id = draft.id
                            this.form.code = draft.code
                            this.form.name = draft.name
                            this.form.tel = draft.tel
                            this.form.company = draft.company
                        }
                        
                        this.accompanyList = accompanyList

                        console.log("routeValue = ", routeValue)
                        
                        this.accompanyForm.name = routeValue.name
                        this.accompanyForm.tel = routeValue.tel
                        this.accompanyForm.company = routeValue.company

                        if (!this.checkAccompanyRepeat()) {
                            this.submitUsualAccompany(routeValue)
                        }
                    }
                }
            })();
        } else {
            (async () => {
                let data = (await getForm(this.APPLY_TYPE_BOOK)).data;

                if ((data.code === 200)&&(data.data != null)) {
                    this.applyForm = this.parseFormType(data.data.formItems);
                    this.addFormVerifyRules();
                    
                    if (this.applyForm != null) {
                        this.applyForm.forEach(item => {
                            if (item.type === 'company') {
                                item.value = this.visitor.company;
                            }
                        })
                    }
                }

                this.printDebug("applyForm => ", this.applyForm);

                if (routeValue != null) {
                    this.form.id = routeValue.id;
                    this.form.code = routeValue.code
                    this.form.name = routeValue.name;
                    this.form.tel = routeValue.tel;
                    this.form.department = routeValue.departmentName;
                }

                this.resetFields(submit_info);
            })();
        }

        //访客须知
        if ((this.accountType === this.ACCOUNT_TYPE_VISITOR)&&(this.ntcSettingInit === false)) {
            getVisitorNotice().then((data) => {
                this.printDebug("notice setting data => ", data)
                
                this.setNtcSettings(data)
                this.showVisitorNotice = this.visitorNtcReadFlag
            }).catch(_ => {
                this.printDebug("get notice setting data error!!!!!")
            })
        } else if ((this.accountType === this.ACCOUNT_TYPE_VISITOR)&&(submit_info == null)) {
            this.showVisitorNotice = this.visitorNtcReadFlag
        }
    },
    
    components: {
        CarKeyboard
    },
    
    computed: {
        ...mapState(['accountType', 'visitor', 'employee', 
                    'ntcSettingInit', 'visitorNotice', 'visitorNtcReadFlag']),

        titleDesc() {
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                return "添加员工"
            } else {
                return "添加访客"
            }
        }
    },

    data() {
        return {
            isLoading: false,

            //访客须知
            showVisitorNotice: false,

            //区域信息
            showAreaPopup: false,

            areaVerifyFlg: false,
            
            parentArea: {
                "value": "",
                "valueList": []
            },
            subArea: {
                "value": "",
                "valueList": []
            },
            
            currentAreaId: null,

            areas: [],
            
            //日期控件
            showDatePopup : false,
            currentDate: null,

            minDate: new Date(),
            maxDate: new Date(2035, 12, 1),

            //picker控件
            showPickerPopup : false,
            columns: [],

            //car keyboard控件
            showCarKeyboardPopup: false,
            
            //访问员工 或者 被邀人
            form : {},
            employeeFormLabel : [
                {
                    prop : 'name',
                    label : '姓名'
                },
                {
                    prop : 'tel',
                    label : '手机号码'
                },
                {
                    prop : 'department',
                    label : '所在部门'
                }
            ],
            
            //
            fieldType: null,
            currentForm: {},
            
            showDialog: false,
            accompanyForm: {},
            accompanyList: [],
            
            //申请需要的字段
            applyBeginTime : '',
            applyEndTime : '',
            beginDate : '',
            beginTime : '',
            endDate : '',
            endTime : '',

            checkoutTime: null,
            
            applyForm : [],

            accompanyFlage: false,

            //表单验证
            rules: {
                applyBeginTime: [{required: true}],
                applyEndTime: [{required: true}]
            },
        }
    },

    methods: {
        ...mapMutations(['setNtcSettings']),

        resetFields(submit_info) {
            if (submit_info == null) {
                return
            }
            
            this.areas = submit_info.areas
            this.areaVerifyFlg = submit_info.areaVerifyFlg
            this.parentArea = submit_info.parentArea
            this.subArea = submit_info.subArea
            this.currentAreaId = submit_info.currentAreaId
            
            this.applyBeginTime = submit_info.applyBeginTime
            this.applyEndTime = submit_info.applyEndTime
            this.beginDate = submit_info.beginDate
            this.beginTime = submit_info.beginTime
            this.endDate = submit_info.endDate
            this.endTime = submit_info.endTime

            if (this.applyForm == null) {
                return
            }

            this.applyForm.forEach((item, index) => {
                this.applyForm[index].value = submit_info.externInfo[index].value
            })
        },

        fillFields() {
            let submit_info = {}

            submit_info.areas = this.areas
            submit_info.areaVerifyFlg = this.areaVerifyFlg
            submit_info.parentArea = this.parentArea
            submit_info.subArea = this.subArea
            submit_info.currentAreaId = this.currentAreaId
            
            submit_info.applyBeginTime = this.applyBeginTime
            submit_info.applyEndTime = this.applyEndTime
            submit_info.beginDate = this.beginDate
            submit_info.beginTime = this.beginTime
            submit_info.endDate = this.endDate
            submit_info.endTime = this.endTime
            submit_info.externInfo = this.applyForm

            return submit_info;
        },

        //在有主动弹出选择框的情况下，禁止系统弹出的框。
        forbidFocus() {
            document.activeElement.blur();
        },

        addPeople() {
            let submit_info = this.fillFields();
            
            if ((this.accountType === this.ACCOUNT_TYPE_VISITOR)) {
                this.$router.push({ 
                    name : "searchemployee",
                    params:{'source':'apply', 'type':"viewed", 'submit_info':submit_info} })
            } else {
                this.$router.push({ 
                    name : "addvisitor", 
                    params:{'source':'apply', 'type':"draft",  'submit_info':submit_info} })
            }
        },

        submitApply() {
            let apply = {}
            
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                if (this.form.id == null) {
                    this.$toast.fail("请选择访问员工")
                    return
                }
                
                apply.visitorId = this.visitor.id
                apply.visitorCode = this.visitor.code
                apply.employeeId = this.form.id
                apply.employeeCode = this.form.code
                apply.type = this.APPLY_TYPE_BOOK
            } else {
                if (this.form.id == null) {
                    this.$toast.fail("请选择邀约人员")
                    return
                }

                apply.visitorId = this.form.id
                apply.visitorCode = this.form.code
                apply.employeeId = this.employee.id
                apply.employeeCode = this.employee.code
                apply.type = this.APPLY_TYPE_INVITE
            }
            
            apply.beginDate = this.beginDate
            apply.beginTime = this.beginTime + ':' + "00"
            apply.endDate = this.endDate
            apply.endTime = this.endTime + ':' + "00"
            apply.forms = this.applyForm
            
            //
            apply.entryArea = this.currentAreaId
            apply.device = "wx_h5"

            //设置签离的时间限制
            apply.checkoutTime = this.parseCheckoutTime()

            //设置同行人
            this.printDebug("accompanyList = ", this.accompanyList)

            let accompanyStr = ""
            
            this.accompanyList.forEach(item => {
                accompanyStr += accompanyStr == "" ? item.code : '|' + item.code
            })

            apply.accompanies = accompanyStr

            this.printDebug("apply =>", apply)

            this.isLoading = true

            addApply(apply).then(({data}) => {
                if (data.code === 200) {
                    this.printDebug("data.data => ", data.data)

                    this.$router.push({ name: "applyresult" })
                } else {
                    this.isLoading = false
                    this.$toast.success(this.SERVER_ERROR_MSG)
                }
            }).catch((error) => {
                this.isLoading = false
                this.printDebug("error => ", error)
                this.$toast.success(this.NETWORK_ERROR_MSG)
            })
        },

        //
        visitorFormFieldClick(item) {
            this.visitorFieldValue = item;

            if (item.prop === "cert_type") {
                document.activeElement.blur()

                this.visitorPickerColumns = ["护照", "身份证"]

                this.showVisitorPickerPopup = true
            }
        },

        areaFieldClick(type) {
            document.activeElement.blur()

            this.columns = []
            this.subArea.valueList = []

            switch(type) {
                case "parent":
                    this.fieldType = "parent"
                    this.parentArea.valueList.forEach(item => {
                        this.columns.push(item)
                    })
                    break;
                case "sub":
                    if (this.parentArea.value === "") {
                        this.$toast.fail("请先选择到访区域")
                        return
                    }

                    this.fieldType = "sub"
                    this.areas.forEach(area => {
                        if (area.name === this.parentArea.value) {
                            this.subArea.valueList.push(...area.areas)

                            this.subArea.valueList.forEach(item => {
                                this.columns.push(item.address)
                            })
                        }
                    })
                    break;
            }
            
            this.showAreaPopup = true
        },
        
        applyTimeFieldClick(type) {
            if (type === "begin") {
                this.minDate = new Date()

                if (this.applyEndTime != '') {
                    let ts = new Date(this.applyEndTime).getTime() - 10 * 60 * 1000
                    this.maxDate = new Date(ts)
                }
            } else if (type === "end") {
                this.maxDate = new Date(2035, 12, 1)

                if (this.applyBeginTime != '') {
                    let ts = new Date(this.applyBeginTime).getTime() + 10 * 60 * 1000
                    this.minDate = new Date(ts)
                } else {
                    let ts = new Date().getTime() + 10 * 60 * 1000
                    this.minDate = new Date(ts)
                }
            }
            
            this.fieldType = type
            this.showDatePopup = true
        },

        //accompany的交互是独立的，在mount阶段解析。
        fieldClick(item) {
            this.currentForm = item
            this.fieldType = 'form'
            
            switch(item.realType) {
                case "timer":
                    document.activeElement.blur()
                    this.showDatePopup = true
                    break;
                case "picker":
                    document.activeElement.blur()
                    this.columns = this.parsePickerValue(item.valueList)
                    this.showPickerPopup = true
                    break;
                case "number":
                    break;
                case "car":
                    document.activeElement.blur()
                    this.showCarKeyboardPopup = true
                    break;
                case "input":
                    break;
                case "file":
                    break;
            }
        },

        onVisitorPickerConfirm(value) {
            this.onBookVisitorPickerConfirm(value, this.accompanyForm);
        },

        onAreaConfirm(value) {
            if (this.fieldType === "parent") {
                this.parentArea.value = value
                this.subArea.value = null
            } else {
                this.subArea.value = value

                this.subArea.valueList.forEach(item => {
                    if (item.address === value) {
                        this.currentAreaId = item.id
                        this.printDebug("Verify areaId =>", this.currentAreaId)
                    }
                })
            }

            this.showAreaPopup = false
        },

        onPickerConfirm(value) {
            this.currentForm.value = value

            this.parsePickerDepend(value)
            this.addFormVerifyRules();

            this.showPickerPopup = false;
        },
        
        onCarKeyboardConfirm(value) {
            this.currentForm.value = value
            
            this.showCarKeyboardPopup = false;
        },
        
        onCarKeyboardCancel() {
            this.showCarKeyboardPopup = false
        },

        onDateConfirm(time) {
            let year = time.getFullYear();

            let month = time.getMonth() + 1;
            month = month <= 9 ? "0" + month : month

            let day = time.getDate();
            day = day <= 9 ? "0" + day : day

            let hours = time.getHours()
            hours = hours <= 9 ? "0" + hours : hours

            let minutes = time.getMinutes()
            minutes = minutes <= 9 ? "0" + minutes : minutes

            let seconds = time.getSeconds()
            seconds = seconds <= 9 ? "0" + seconds : seconds
            
            let dateStr = year + '-' + month + '-' + day
            // let timeStr = hours + ':' + minutes + ':' + seconds
            let timeStr = hours + ':' + minutes
            let str = dateStr +  ' ' + timeStr
            
            //赋值给对应的变量
            if (this.fieldType === 'begin') {
                this.beginDate = dateStr
                this.beginTime = timeStr
                this.applyBeginTime = str
            } else if (this.fieldType === 'end') {
                this.endDate = dateStr
                this.endTime = timeStr
                this.applyEndTime = str
            } else {
                this.currentForm.value = str
            }

            this.showDatePopup = false;
        },

        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            } else if (type === 'day') {
                return `${val}日`;
            } else if (type === 'hour') {
                return `${val}时`;
            } else if (type === 'minute') {
                return `${val}分`;
            }

            return val;
        },
        
        filter(type, options) {
            if (type === 'minute') {
                return options.filter((option) => option % 10 === 0);
            }

            return options;
        },

        addUsualAccompany() {
            let submit_info = this.fillFields();

            let extern_info = {
                "draft": this.form,
                'accompanyList': this.accompanyList,
            }

            this.$router.push({
                name: "addvisitor",
                params:{'source':'apply', 
                        'type':"accompany",
                        'submit_info':submit_info,
                        'extern_info':extern_info} })
        },

        submitUsualAccompany(visitor) {
            this.accompanyList.unshift(visitor)
            this.accompanyForm = {}
        },

        addAccompany() {
            if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
                (async () => {
                    let setting = await getVisitorSettings();

                    this.printDebug("setting = ", setting);

                    if (setting != null) {
                        this.setBookVisitorLabel(setting);
                    }
                    
                    //
                    this.accompanyForm.company = this.visitor.company

                    this.showDialog = true
                })();
            } else if ((this.form != null)&&(this.form.company != null)) {
                this.accompanyForm.company = this.form.company
                this.showDialog = true
            }
        },

        cancelAccompany() {
            this.showDialog = false
            this.accompanyForm = {}
        },

        submitAccompany() {
            if (this.checkAccompanyRepeat()) {
                return
            }

            if ((this.accompanyForm.name.trim() === "")||(this.accompanyForm.company.trim() === "")) {
                this.$toast.fail("请填写合法的姓名和公司")
                return
            }
            
            addCompanyVisitor(this.accompanyForm).then(({data}) => {
                this.accompanyList.unshift(data.data)
                this.showDialog = false
                this.accompanyForm = {}
            }).catch(_ => {
                this.$toast.fail("添加失败")
            })
        },

        deleteAccompany(item) {
            this.accompanyList = this.accompanyList.filter(accompany => accompany.id !== item.id)
        },

        checkAccompanyRepeat() {
            if ((this.accountType === this.ACCOUNT_TYPE_VISITOR)
                &&(this.accompanyForm.tel === this.visitor.tel)) {
                    this.$toast.fail("和申请人电话重复")
                    return true
            } else if ((this.accountType === this.ACCOUNT_TYPE_EMPLOYEE)
                &&(this.form.tel != null)&&((this.accompanyForm.tel === this.form.tel))) {
                    this.$toast.fail("和邀约人电话重复")
                    return true
            }

            for (let i = 0; i < this.accompanyList.length; i++) {
                if (this.accompanyList[i].tel === this.accompanyForm.tel) {
                    this.$toast.fail("申请人电话重复")
                    return true
                }
            }

            return false
        }
    }
}

</script>

<style lang="less" scoped>

.ntc-dialog {
    width: 90vw;
    height: 90vh;
    
    top: 50%;
    
    /deep/.van-dialog__header {
        font-weight: 600;
        font-size: 18px;
    }

    .main {
        margin: 0px 10px 0px 10px;
    
        /deep/ ul {
            list-style:disc inside !important;
        }

        /deep/ ol {
            list-style:decimal inside !important;
        }
    }
    
    .bottom-button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 20px;
    }
}

.dialog {
    .dialog-header {
		display: flex;
		justify-content: center;
		color: green;
	}

	.dialog-footer {
		margin-bottom: 10px;
        margin-top: 20px;
	}
}

.carKeyboardContainer {
    padding-top: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.manage {
    background: rgb(81, 126, 230);
    height: 160px;

    .title {
        padding-top: 30px;

        .title_left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                padding: 5px 0px;
                margin: 0px;
            }
        }
        
        .van-cell {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

.apply {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 20px 10px 20px 10px;

    background-color: white;
    
    border-radius: 15px;
    border:0.5px solid #efefef;
    box-shadow: 0 0 15px #606266;

    .van-cell {
        /deep/.van-field__label {
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 0px 10px;
        }

        /deep/.van-field__value {
            padding: 0px 10px 0px 0px;
            margin: 0px 10px 0px 0px;
        }
    }

    .top {
        margin-top: 20px;
    }

    .footer {
        margin-bottom: 20px;
    }
}

.accompany {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 20px 10px 30px 10px;

    background-color: white;
    
    border-radius: 15px;
    border:0.5px solid #efefef;
    box-shadow: 0 0 15px #606266;

    // .van-cell {
    //     /deep/.van-field__label {
    //         padding: 0px 0px 0px 10px;
    //         margin: 0px 0px 0px 10px;
    //     }

    //     /deep/.van-field__value {
    //         padding: 0px 10px 0px 0px;
    //         margin: 0px 10px 0px 0px;
    //     }
    // }

    // .content-top {
    //     margin-top: 20px;
    // }

    // /deep/.content-footer {
    //     margin-bottom: 20px;
    //     // padding-bottom: 20px;
    // }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-top: 10px;
        margin-bottom: 20px;
        
        font-size: 18px !important;
        font-weight: 550;
    }

    .accompany-list {
        display: flex;
        flex-flow:row wrap;
        margin-left: 20px;
    }
}

.van-tag {
    height: 32px;
}

</style>
