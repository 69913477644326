<template>
    <div class="manage">
        <van-search class="search" v-model="searchValue" shape="round" show-action background="rgb(222, 231, 248)" 
                    placeholder="请输入 手机号码" @search="search" >
            <template #action>
                <div style="font-size: 16px; margin: 0px 5px 0px 5px"
                    plain round type="primary" @click="search">搜索</div>
            </template>
        </van-search>

        <div class="area">
            <van-form v-for="(item, index) in employeeList" :key="index">
                <div @click="fieldClick(item)">
                    <van-field v-model="item.tel" readonly label-width="70px" label="手机号码:"/>
                    <van-field v-model="item.infos" readonly label-width="70px" label="员工信息:"/>
                </div>
            </van-form>
        </div>

        <div class="bottom">
            <van-tabbar style="height: 40px; margin-top:20px" fixed placeholder safe-area-inset-bottom>
                <van-tabbar-item @click="goToBack()" style="color: red; font-size: 16px !important;">
                    返 回
                </van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
import {getSearchEmployee} from '@/api/employee-api'
import {addUsualEmployee, getUsualEmployee, updateUsualEmployee} from '@/api/usual-api'

export default {
    mounted() {
        this.source = this.$route.params.source
        this.submit_info = this.$route.params.submit_info

        this.printDebug("this.source => ", this.$route.params.source)
        this.printDebug("this.submit_info => ", this.$route.params.submit_info)

        getUsualEmployee().then(({data}) => {
            this.printDebug("data => ", data.data)

            this.usualEmployees = data.data == null ? [] : data.data

            this.usualEmployees.forEach((item, index) => {
                this.usualEmployees[index].infos = item.name + " | " + item.departmentName
            })

            this.employeeList = this.usualEmployees
        }).catch(_ => {
            // this.$toast.fail(this.NETWORK_ERROR_MSG)
        })
    },

    data() {
        return {
            submit_info: null,
            source: '',
            usualEmployees: [],
            employeeList: [],
            searchValue: ''
        }
    },
    
    methods: {
        search() {
            if (this.searchValue === "") {
                this.employeeList = this.usualEmployees
                return
            }

            let employee = {}

            employee.tel = this.searchValue

            getSearchEmployee(employee).then(({data}) => {
                if (data.code === 200) {
                    data.data.forEach((item, index) => {
                        data.data[index].infos = item.name + " | " + item.departmentName
                    })

                    this.employeeList = data.data
                } else {
                    this.$toast.fail(this.SERVER_ERROR_MSG)
                }
            }).catch((error) => {
                console.log("error =>", error)
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        },

        fieldClick(item) {
            let exist_flage = 0;//不存在

            for (let i = 0; i < this.usualEmployees.length; i++) {
                let record = this.usualEmployees[i]

                if (record == null) {
                    continue
                }

                if (record.tel === item.tel) {
                    exist_flage = 1
                    break
                }
            }

            if (exist_flage === 0) {
                addUsualEmployee(item.code).then(({data}) => {
                    if (data.code === 200) {
                        return 200
                    } else {
                        return 201
                    }
                }).then((code) => {
                    if ((this.source === 'usualmanage') && (code === 201)) {
                        this.$toast.fail(this.SERVER_ERROR_MSG)
                    } else {
                        this.$router.push({
                            name: this.source,
                            params: {
                                'source': 'search',
                                'value': item,
                                "submit_info": this.submit_info
                            }
                        })
                    }
                }).catch(_ => {
                    this.$toast.fail(this.NETWORK_ERROR_MSG)
                })
            } else {
                updateUsualEmployee(item.code);

                this.$router.push({
                    name: this.source,
                    params: {
                        'source': 'search',
                        'value': item,
                        "submit_info": this.submit_info
                    }
                })
            }
        },

        goToBack() {
            this.$router.push({
                name: this.source,
                params: {
                    'source': 'search',
                    'value': null,
                    "submit_info": this.submit_info
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>

.van-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 80px;

    margin: 10px 10px 0px 10px;

    border-radius: 15px;
    border: 0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;

    .van-cell {
        padding: 0px 0px 0px 10px;
    }

    div {
        margin: 0px;
        padding: 0px;
    }
}

.manage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;

    .search {
        flex: 0 0 auto;
    }

    .area {
        flex: 1 0 auto;

        .van-form {
            display: flex;
            flex-direction: column;
            justify-content: center;

            height: 80px;

            margin: 10px 10px 0px 10px;

            border-radius: 15px;
            border: 0.5px solid rgb(81, 126, 230);
            box-shadow: 0 0 15px #606266;

            .van-cell {
                padding: 0px 0px 0px 10px;
            }

            div {
                margin: 0px;
                padding: 0px;
            }
        }

        /deep/ .van-field__label {
            color: #67C23A;
        }
    }

    .bottom {
        flex: 0 0 auto;
    }
}

</style>
