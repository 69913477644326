import http from "./config/http-configs";

export const getForm = (type) => {
    return http.get(`/mobile/apply/form/info/${type}`)
}

export const addApply = (apply) => {
    return http.post(
        '/mobile/apply/add/',
        apply,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const cancelApply = (apply) => {
    return http.post(
        '/mobile/apply/cancel/',
        apply,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const approveApply = (apply) => {
    return http.post(
        '/mobile/apply/approve/',
        apply,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const transferApply = (apply) => {
    return http.post(
        '/mobile/apply/transfer/',
        apply,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getApproves = (applyId) => {
    return http.get(`/mobile/apply/approve/info/${applyId}`)
}

export const getMakeCopies = (applyId) => {
    return http.get(`/mobile/apply/copy/info/${applyId}`)
}

export const getTransfers = (applyId) => {
    return http.get(`/mobile/apply/transfer/info/${applyId}`)
}

export const getAccompanies = (applyId) => {
    return http.get(`/mobile/apply/accompany/info/${applyId}`)
}

//员工
export const getValidPendingAppliesByEmployeeId = () => {
    return http.get('/mobile/apply/employee/pending/valid')
}

export const getInvalidPendingAppliesByEmployeeId = (page) => {
    return http.post(
        '/mobile/apply/employee/pending/invalid',
        page,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getValidFinishAppliesByEmployeeId = () => {
    return http.get('/mobile/apply/employee/finish/valid')
}

export const getInvalidFinishAppliesByEmployeeId = (page) => {
    return http.post(
        '/mobile/apply/employee/finish/invalid',
        page,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

//访客
export const getValidPendingAppliesByVisitorId = () => {
    return http.get('/mobile/apply/visitor/pending/valid')
}

export const getInvalidPendingAppliesByVisitorId = (page) => {
    return http.post(
        '/mobile/apply/visitor/pending/invalid',
        page,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getValidFinishAppliesByVisitorId = () => {
    return http.get('/mobile/apply/visitor/finish/valid')
}

export const getInvalidFinishAppliesByVisitorId = (page) => {
    return http.post(
        '/mobile/apply/visitor/finish/invalid',
        page,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}
