import Vue from "vue";
import VueRouter from "vue-router";

import StartGuide from "../pages/guide/StartGuide.vue"
import GeneralGuide from "../pages/guide/GeneralGuide.vue"
import QywxGuide from "../pages/guide/QywxGuide.vue"

import Home from "../components/Home"

import Apply from "../pages/apply/Apply"
import ApplyResult from '../pages/apply/ApplyResult'

import AccountLogin from "../pages/account/AccountLogin"
import AccountDetail from "../pages/account/AccountDetail"
import AccountSync from "../pages/account/AccountSync.vue"

import UsualManage from "../pages/usual/UsualManage"
import AddVisitor from '../pages/usual/AddVisitor'
import SearchEmployee from '../pages/usual/SearchEmployee'

import UserAgreement from "../pages/UserAgreement"
import DetailRoute from "../pages/DetailRoute"

import EmployeeRecords from "../pages/record/EmployeeRecords.vue"
import VisitorRecords from "../pages/record/VisitorRecords.vue"
import RecordDetail from "../pages/record/RecordDetail.vue"
import Transfer from "../pages/record/Transfer.vue"
import CheckOut from "../pages/record/CheckOut.vue"
import Approve from "../pages/record/Approve.vue"
import OperateResult from "../pages/record/OperateResult.vue"

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/guide",
            meta: {title: '首页'}
        },
        {
            name: 'guide',
            path: "/guide",
            component: StartGuide,
            meta: {title: '首页'}
        },
        {
            name: 'generalguide',
            path: "/generalguide",
            component: GeneralGuide,
            meta: {title: '首页'}
        },
        {
            name: 'qywxguide',
            path: "/qywxguide",
            component: QywxGuide,
            meta: {title: '首页'}
        },
        {
            name: 'home',
            path: "/home",
            component: Home,
            meta: {title: '首页'}
        },
        {
            name: 'apply',
            path: "/apply",
            component: Apply,
            meta: {title: '访问申请'}
        },
        {
            name: 'applyresult',
            path: "/applyresult",
            component: ApplyResult,
            meta: {title: '申请结果'}
        },
        {
            name: 'login',
            path: "/login",
            component: AccountLogin,
            meta: {title: '账号登陆'}
        },
        {
            name: 'accountdetail',
            path: "/accountdetail",
            component: AccountDetail,
            meta: {title: '完善信息'}
        },
        {
            name: 'accountsync',
            path: "/accountsync",
            component: AccountSync,
            meta: {title: '账号登录'}
        },
        {
            name: 'addvisitor',
            path: "/addvisitor",
            component: AddVisitor,
            meta: {title: '常邀访客'}
        },
        {
            name: 'searchemployee',
            path: "/searchemployee",
            component: SearchEmployee,
            meta: {title: '员工搜索'}
        },
        {
            name: 'usualmanage',
            path: "/usualmanage",
            component: UsualManage,
            meta: {title: '常访管理'}
        },
        {
            name: 'useragreement',
            path: "/useragreement",
            component: UserAgreement,
            meta: {title: '用户协议'}
        },
        {
            name: 'employeerecords',
            path: "/employeerecords",
            component: EmployeeRecords,
            meta: {title: '记录页面'}
        },
        {
            name: 'visitorrecords',
            path: "/visitorrecords",
            component: VisitorRecords,
            meta: {title: '记录页面'}
        },
        {
            name: 'recorddetail',
            path: "/recorddetail",
            component: RecordDetail,
            meta: {title: '详情页'}
        },
        {
            name: 'transfer',
            path: "/transfer",
            component: Transfer,
            meta: {title: '转签页面'}
        },
        {
            name: 'checkout',
            path: "/checkout",
            component: CheckOut,
            meta: {title: '签离确认'}
        },
        {
            name: 'approve',
            path: "/approve",
            component: Approve,
            meta: {title: '审批页面'}
        },
        {
            name: 'operateresult',
            path: "/operateresult",
            component: OperateResult,
            meta: {title: '操作结果'}
        },
        {
            name: 'detailroute',
            path: "/mjlp/:key",
            component: DetailRoute
        }
    ],
    
    mode: "history"
})
