import http from "./config/http-configs";

//访客
export const addUsualEmployee = (employeeCode) => {
    return http.get(`/mobile/usual/employee/add/${employeeCode}`)
}

export const updateUsualEmployee = (employeeCode) => {
    return http.get(`/mobile/usual/employee/update/${employeeCode}`)
}

export const deleteUsualEmployee = (employeeCodes) => {
    return http.post(
        `/mobile/usual/employee/delete?employeeCodes=${employeeCodes}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getUsualEmployee = () => {
    return http.get('/mobile/usual/employee/list')
}

//员工
export const addUsualVisitor = (visitorCode) => {
    return http.get(`/mobile/usual/visitor/add/${visitorCode}`)
}

export const updateUsualVisitor = (visitorCode) => {
    return http.get(`/mobile/usual/visitor/update/${visitorCode}`)
}

export const deleteUsualVisitor = (visitorCodes) => {
    return http.post(
        `/mobile/usual/visitor/delete?visitorCodes=${visitorCodes}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}

export const getUsualVisitor = () => {
    return http.get('/mobile/usual/visitor/list')
}
