<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}

//ios的页面缩放，main.js中设置的meta对ios无效。
window.onload = function() {
	// 禁用双指放大
	document.addEventListener('touchstart', function(event) {
		if (event.touches.length > 1) {
			event.preventDefault()
		}
	});

	document.addEventListener('gesturestart', function(event) {
		event.preventDefault()
	})

	// 禁用双击放大
	var lastTouchEnd = 0;

	document.documentElement.addEventListener('touchend', function (event) {
		var now = Date.now();
		if (now - lastTouchEnd <= 300) {
			event.preventDefault();
		}

		lastTouchEnd = now;
	}, 
	{
		passive: false
	});
};

</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

/* body {
  margin: 0px;
  padding: 0px;
}

p {
  padding: 0px;
}

div {
  padding: 0px;
} */

</style>
