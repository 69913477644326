<template>
    <div>
        <div class="header">
            <van-image style="width:120px; height: 120px" round :src="require('../../assets/result.png')"/>
            <p>成功</p>
        </div>
        
        <div v-if="showJumpButton">
            <van-row style="margin-bottom:20px;margin-top:5px">
                <van-col span="6">
                </van-col>

                <van-col span="12">
                    <van-button size="normal" round block type="info" @click="onClick('home')">
                        返回首页
                    </van-button>
                </van-col>

                <van-col span="6">
                </van-col>
            </van-row>

            <van-row>
                <van-col span="6">

                </van-col>

                <van-col span="12">
                    <van-button size="normal" round block type="danger" @click="onClick('detail')">
                        返回记录页 
                    </van-button>
                </van-col>

                <van-col span="6">
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.source = this.$route.params.source
        this.type = this.$route.params.type
        this.active = this.$route.params.active
    },

    data() {
        return {
            source: null,
            type: null,
            active: null
        }
    },

    computed: {
        showJumpButton() {
            if (this.source == null) {
                return false
            }

            return true
        }
    },

    methods: {
        onClick(value) {
            if (value === 'home') {
                this.$router.push({ name: "home" })
            } else {
                this.$router.push({ name: this.source, params: {active : this.active} })
            }
        }

    }
}
</script>

<style lang="less" scoped>

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 40vh;
}

</style>
