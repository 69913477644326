<template>
  <div id="rid-m-201708101425">
    <div class="choice-keyboard-type">
      <!--  原生样式不好调，可以改用 vant or el 的样式  -->
      <input class="keyboard-radio" type="radio" id="normal" v-model="keyboardSelectType" value="normal">
      <label class="label-style" for="normal">
        <span class="choice-radio"></span><span class="choice-item">普通车牌</span>
      </label>

      <input class="keyboard-radio" type="radio" id="green" v-model="keyboardSelectType" value="green">
      <label class="label-style" for="green">
        <span class="choice-radio"></span><span class="choice-item">新能源车牌</span>
      </label><br>
    </div>
    <number-view
        class="left-right-15"
        :numberArray="numberArray"
        :mode="dyDisplayMode"
        :selectedIndex="dyCurrentIndex"
        @oncellselected="onUserSelectedInput"
    />
    <div id="divider"/>
    <keyboard-view
        class="left-right-15"
        :keyboard="dyKeyboard"
        :keycount="dyKeyCount"
        @onpadkeyclick="onClickPadKey"
        @onpadshowmoreclick="onClickShowALL"
    />
    <div style="height: 10px"/>
    <div class="bottom-function">

      <button class="button-style" style="background-color: #2fc25b" @click="onButtonCleanClick">清空</button>
      <div>
        <button class="button-style" style="background-color: #efefef;margin-right: 5px; color: gray"
                @click="onButtonBackClick">
          返回
        </button>
        <button :disabled="!ensureButtonEnable" class="button-style button-color-ensure" style="margin-left: 5px"
                @click="onButtonEnsureClick">
          确认
        </button>
      </div>
      <button class="button-style" style="background-color: #d00" @click="onButtonDelClick">删除</button>
    </div>
  </div>

</template>

<script>

import KeyboardView from "./comp/KeyboardView.vue";
import NumberView from "./comp/NumberView.vue";

var Keyboard = require("./js/engine.js");
var FastProvince = require("./js/provinces.js");

var engine = new Keyboard();
var provinces = new FastProvince();

var _ClickEvent = {
  KEY: 0,
  OK: 1,
  DEL: 2
};

var __store = {
  keyboardSelectType: "normal",
  ensureButtonEnable: false,
  args: {
    number: "",
    presetNumber: "",
    keyboardType: 0,
    inputIndex: 0,
    numberType: 0,
    provinceName: "",
    showProvince: true,
    province: "",
  },
  numberArray: ["", "", "", "", "", "", ""], // 用户输入的车牌数据
  userMode: Keyboard.NUM_TYPES.AUTO_DETECT, // 用户设定的车牌号码模式
  detectNumberType: Keyboard.NUM_TYPES.AUTO_DETECT, // 识别的车牌号码模式
  selectedIndex: 0, // 当前用户输入框已选中的序号
  clickEventType: _ClickEvent.KEY, // 用户动作类型
  showShortcuts: true, // 需要显示省份简称
  userChanged: false,

  ////// 以下是 WidgetInput 组件的辅助函数

  // 返回当前已输入的车牌号码
  getNumber: function () {
    return this.numberArray.join("");
  },

  // 返回当前车牌是否已输入完成
  isCompleted: function () {
    return this.getNumber().length === this.numberArray.length;
  },

  // 选中下一个输入序号的输入框
  selectNextIndex: function () {
    var next = this.selectedIndex + 1;
    if (next <= (this.numberArray.length - 1)/*限定在最大长度*/) {
      this.selectedIndex = next;
    }
  },

  setNumberTxtAt: function (index, text) {
    this.$set(this.numberArray, index, text);
    this.resetUserChanged();
  },

  setLengthTo8: function () {
    console.log("setLengthTo8")
    // 当前长度为7位长度时才允许切换至8位长度
    if (this.numberArray.length === 7) {
      // 扩展第8位：当前选中第7位，并且第7位已输入有效字符时，自动跳转到第8位
      if (6 === this.selectedIndex && this.getNumber().length === 7) {
        this.selectedIndex = 7;
      }
      this.numberArray.push("");
      this.resetUserChanged();
    }
  },

  setLengthTo7: function () {
    console.log("setLengthTo7")

    if (this.numberArray.length === 8) {
      if (7 === this.selectedIndex) { // 处理最后一位的选中状态
        this.selectedIndex = 6;
      }
      this.numberArray.pop();
      this.resetUserChanged();
    }
  },
  // 重置外部用户修改车牌标记位
  resetUserChanged: function () {
    this.userChanged = false;
  },

  /////// 以下是 全局 辅助函数

  // 返回用户是否外部修改了车牌号码
  isUserChangeNumber: function () {
    return this.userChanged === true;
  },

  // 同步输入框长度
  syncInputLength: function (mode, forceNewEnergyMode) {
    // 键盘引擎根据输入参数，会自动推测出当前车牌的类型。
    // 如果当前用户没有强制设置，更新键盘的输入框长度以适当当前的车牌类型,（指地方武警车牌，长度为8位）
    if (forceNewEnergyMode) { // 强制新能源类型，应当设置为：8位
      this.setLengthTo8();
    } else {
      if (Keyboard.NUM_TYPES.WUJING_LOCAL === mode || Keyboard.NUM_TYPES.NEW_ENERGY === mode) { // 地方武警，应当设置为：8位
        this.setLengthTo8();
      } else { // 其它车牌，应当设置为：7位
        this.setLengthTo7();
      }
    }
  },

  // 当用户选择的车牌模式为非AUTO_DETECT模式时，使用用户强制设置模式：目前用户选择的模式有两个值：AUTO_DETECT / NEW_ENERGY
  getUpdateMode: function () {
    if (this.userMode === Keyboard.NUM_TYPES.NEW_ENERGY) {
      return Keyboard.NUM_TYPES.NEW_ENERGY;
    } else {
      return Keyboard.NUM_TYPES.AUTO_DETECT;
    }
  }
};

export default {
  name: "MixedKeyboard",
  props: ["callbacks"],
  data: function () {
    return __store;
  },
  mounted() {
    this.numberArray = ["", "", "", "", "", "", ""]
    this.keyboardSelectType = "normal"
    this.selectedIndex = 0
  },
  // 数据变化监听
  watch: {
    "numberArray": function (a) {
      this.args.number = this.getNumber();
      this.ensureButtonEnable = this.isCompleted();
    },
    "args.changedseed": function (a) {
      this.numberArray = _rebuildNumberArray(this.args.number, this.numberArray.length/*要保证与原生长度一致*/);
      // 在用户更新车牌后，选中位置设置为当前车牌可输入的最后一位
      this.selectedIndex = Math.max(0, Math.min(this.numberArray.length - 1, this.getNumber().length));
      this.userChanged = true
      this.showShortcuts = true;
    },
    "keyboardSelectType": function (value) {
      if (value === "normal") {
        this.userMode = Keyboard.NUM_TYPES.AUTO_DETECT;
        this.setLengthTo7();
      } else {
        this.setLengthTo8();
        this.userMode = Keyboard.NUM_TYPES.NEW_ENERGY;
      }
    }
  },
  computed: {
    dyKeyCount: function () {
      return this.__arrayOf(this.dyKeyboard, "row0").length;
    },
    dyKeyboardType: function () {
      return this.args.keyboardType; // 计算属性，键盘类型
    },
    dyCurrentIndex: function () {
      return this.selectedIndex; // 计算属性，当前选中输入框的序号
    },
    dyDisplayMode: function () { // 用于显示的车牌模式
      if (this.keyboardSelectType === "normal") {
        return Keyboard.NUM_TYPES.AUTO_DETECT;
      } else {
        return Keyboard.NUM_TYPES.NEW_ENERGY;
      }
    },
    dyKeyboard: function () {
      if (0 === this.dyCurrentIndex  // 选中第一位输入框时；
          && this.args.province.length >= 2 // 当前为有效的省份名字
          && this.showShortcuts) { // 标记为强制显示快捷省份；
        var keyboard = {
          shortcuts: provinces.locationOf(this.args.province).peripheryShortnames().map(function (name) {
            return Keyboard.$newKey(name);
          }).slice(0, 6)// 只返回6个
        };
        // 如果快捷省份数据不存在(快捷省份包括当前省份和周边省份数据)，则返回全键盘数据。
        if (keyboard.shortcuts.length > 1) {
          try {
            return keyboard;
          } finally {
            this.submitprovince(keyboard);
          }
        }
      }
      return this.updatekeyboard();
    }
  },
  methods: {

    // 点击显示更多省份信息：相当于人工点击第一个输入框并强制显示键盘
    onClickShowALL: function () {
      this.onUserSelectedInput(0, true/*强制显示键盘*/);
    },
    // 选中输入框
    onUserSelectedInput: function (index, shouldShowKeyboard) {
      var length = this.getNumber().length;
      if (length > 0 && index <= length) {
        this.selectedIndex = index;
      }
      if (true === shouldShowKeyboard) { /*强制显示键盘*/
        this.showShortcuts = false;
      } else {
        this.showShortcuts = (this.selectedIndex === 0);
      }
    },
    // 点击键位
    onClickPadKey: function (key) {
      if (key.isFunKey) {
        this.onFuncKeyClick(key);
      } else {
        this.onTextKeyClick(key.text);
      }
    },
    // 点击字符按键盘
    onTextKeyClick: function (text, forceUpdate) {
      this.clickEventType = _ClickEvent.KEY;
      if (true === forceUpdate || text !== this.numberArray[this.selectedIndex]) {
        this.setNumberTxtAt(this.selectedIndex, text);
      }
      var number = this.getNumber();
      this.selectNextIndex(); // 选中下一个输入框
      this.callbacks.onChanged(number);

    },
    // 点击功能键
    onFuncKeyClick: function (key) {
      if (key.keyCode === Keyboard.KEY_TYPES.FUN_DEL) {
        this.clickEventType = _ClickEvent.DEL;
        // 删除车辆号码的最后一位
        var maxIndex = this.numberArray.length - 1;
        var deleteIndex = Math.max(0, maxIndex);
        for (var i = maxIndex; i >= 0; i--) {
          if (this.numberArray[i].length !== 0) {
            deleteIndex = i;
            break;
          }
        }
        this.setNumberTxtAt(deleteIndex, "");
        // 更新删除时的选中状态
        this.selectedIndex = deleteIndex;
        this.callbacks.onChanged(this.getNumber());
      } else if (key.keyCode === Keyboard.KEY_TYPES.FUN_OK) {
        this.clickEventType = _ClickEvent.OK;
        // 用户主动点击“确定”按钮，触发回调
      }
    },
    // 更新键盘：当WidgetInput上的数据发生变化时，会触发键盘更新
    updatekeyboard: function () {
      var number = this.getNumber();

      var updatedKeyboard = engine.update(this.dyKeyboardType, this.dyCurrentIndex, this.getNumber(), this.getUpdateMode());
      // console.log("@@@", "updatekeyboard", updatedKeyboard)

      this.detectNumberType = updatedKeyboard.numberType;
      var modeName = Keyboard.NUM_TYPES.nameOf(updatedKeyboard.numberType);
      console.log("更新键盘数据，车牌: " + number + "，模式：" + modeName + "，车牌限制长度：" + updatedKeyboard.numberLimitLength);
      // 将识别结果的车牌模式同步到用户选择模式上
      if (updatedKeyboard.numberType === Keyboard.NUM_TYPES.NEW_ENERGY) {
        this.userMode = Keyboard.NUM_TYPES.NEW_ENERGY;
      } else {
        this.userMode = Keyboard.NUM_TYPES.AUTO_DETECT;
      }
      // this.syncInputLength(updatedKeyboard.numberType, (this.userMode === Keyboard.NUM_TYPES.NEW_ENERGY)/*force to set NewEnergy mode*/);
      this.autocommitsinglekey(updatedKeyboard);
      return updatedKeyboard;
    },
    // 当键盘数据只有一个键位可选择时,自动提交点击事件:(武警车牌第二位J和使馆车最后一位)
    autocommitsinglekey: function (layout) {
      if (this.clickEventType === _ClickEvent.KEY) {
        var availableKeys = layout.keys.filter(function (k) {
          return k.enabled && !k.isFunKey;
        });
        if (availableKeys.length === 1) {
          var self = this;
          setTimeout(function () {
            self.onTextKeyClick(availableKeys[0].text);
          }, 32);
        }
      }
    },
    // 如果当前为空车牌号码，自动提交第一位快捷省份汉字
    submitprovince: function (layout) {
      // 注意：如果是用户点击删除按钮，退回到第一位。则不自动提交第一位快捷省份汉字。
      // 注意：如果用户外部重新设置了空的车牌号码，则需要自动提交
      if (this.getNumber().length === 0 &&
          (this.clickEventType === _ClickEvent.KEY || this.isUserChangeNumber())) {
        var self = this;
        setTimeout(function () {
          if (self.selectedIndex === 0) { // 注意检查当自动提交省份时，输入框选中位置是否在第一位上
            self.onTextKeyClick(layout.shortcuts[0].text);
          }
        }, 32);
      }
    },
    __arrayOf: function (obj, fieldName) {
      var arr = obj[fieldName];
      return arr ? arr : [];
    },

    onButtonDelClick() {
      this.clickEventType = _ClickEvent.DEL;
      // 删除车辆号码的最后一位
      var maxIndex = this.numberArray.length;
      var deleteIndex = maxIndex;
      for (var i = maxIndex - 1; i >= 0; i--) {
        if (this.numberArray[i].length !== 0) {
          deleteIndex = i;
          break;
        }
      }
      if (deleteIndex < maxIndex) {
        console.log("deleteIndex", deleteIndex)
        this.setNumberTxtAt(deleteIndex, "");
        // 更新删除时的选中状态
        this.selectedIndex = deleteIndex;
        this.callbacks.onChanged(this.getNumber());
      }
    },
    onButtonCleanClick() {
      for (let i = 0; i < this.numberArray.length; i++) {
        this.setNumberTxtAt(i, "");
      }
      this.selectedIndex = 0;
      this.callbacks.onChanged(this.getNumber());
    },
    onButtonBackClick() {
      this.callbacks.onBackClick()
    },
    onButtonEnsureClick() {
      this.callbacks.onCommitClick(this.getNumber(), this.isCompleted);
    },
  },
  components: {NumberView, KeyboardView},
}

//////// 以下是工具类函数

// 将车牌号码，生成一个车牌字符数组
function _rebuildNumberArray(updateNumber, originLength) {
  var output = ["", "", "", "", "", "", ""]; // 普通车牌长度为7位，最大长度为8位
  if (originLength > 7) {
    output.push("");
  }
  if (updateNumber != undefined && updateNumber.length != 0) {
    var size = Math.min(8, updateNumber.length);
    for (var i = 0; i < size; i++) {
      output[i] = updateNumber.charAt(i);
    }
  }
  return output;
}

function _isEnergyNumber(number) {
  return /\W[A-Z][0-9DF][0-9A-Z]\d{3}[0-9DF]/.test(number);
}

</script>

<style>
@import "./css/keyboard.css";


/* 键盘框的高度，占比为 80% */
div#divider {
  height: 30px;
}

</style>

<style scoped lang="less">
div#rid-m-201708101425 {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  //text-align: center; font-family: "PingFangSC-Regular", "Helvetica Neue", Arial, sans-serif;
}

.keyboard-radio {
  display: none;
}

input[type="radio"]:checked + label .choice-radio {
  background-color: #2196F3; /* 设置选中时的背景颜色 */
}

/* 隐藏原始的单选按钮标签 */
input[type="radio"] + label.choice-radio {
  display: inline-block;
  cursor: pointer;
}

.choice-keyboard-type {
  margin-left: 15px;
  margin-bottom: 25px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .label-style {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
    height: 30px;
    line-height: 30px;
  }
}

.choice-radio {
  display: inline-block;
  width: 20px; /* 设置单选按钮的宽度 */
  height: 20px; /* 设置单选按钮的高度 */
  background-color: white; /* 设置单选按钮的背景颜色 */
  border-radius: 50%; /* 将单选按钮的形状设为圆形 */
  border: 1px solid gray;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.choice-item {
  margin-right: 10px;
}

#rid-m-201708101425 > .bottom-function {
  margin-left: 15px;
  margin-right: 15px;

  height: 45px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}


.button-style {
  height: 25px;
  width: 60px;
  border-radius: 8px;
  color: white;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 0;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
}

.button-color-ensure {
  background-color: #6dbfff;
}

.button-color-ensure[disabled] {
  background-color: #6dbfff;
  opacity: 0.4;
}

.left-right-15 {
  margin-left: 15px;
  margin-right: 15px;
}
</style>