import http from "./config/http-configs";

export const getQywxInfo = () => {
    return http.get('/mobile/qywx/list/info')
}

export const qywxLogin = (code) => {
    return http.post(
        `/mobile/qywx/login?code=${code}`,
        null,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
            },
            // withCredentials : true
        }
    )
}
