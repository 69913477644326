import http from "@/api/config/http-configs";

export const uploadFile = (file, path) => {
    const formData = new FormData();
    
    formData.append('file', file);
    formData.append('path', path);
    
    return http.post(
        '/mobile/file/upload',
        formData,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
                'Content-Type': 'multipart/form-data'
            },
            // withCredentials : true
        }
    )
}

function base64ToFile(base64String, filename) {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const uploadBase64File = (file, path) => {
    if (!file.startsWith('data:image')) {
        file = "data:image/jpeg;base64," + file;
    }

    const fileName = path.split('/').pop();
    
    // 将 base64 编码的图片转换为文件对象
    const convertedFile = base64ToFile(file, fileName);
    
    //
    const formData = new FormData();

    formData.append('file', convertedFile);
    formData.append('path', path);
    
    return http.post(
        '/mobile/file/upload',
        formData,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',  //解决cors头问题
                // 'Access-Control-Allow-Credentials':'true', //解决session问题
                // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' //将表单数据传递转化为form-data类型
                // 'Content-Type': 'application/json'
                'Content-Type': 'multipart/form-data'
            },
            // withCredentials : true
        }
    )
};

export const getDownloadUrl = () => {
    return "/api/file/download/"
}
