<template>
    <div>
        <div v-if="this.type === 'user'" class="main" v-html="userAgreement"/>

        <div v-else class="main" v-html="secretAgreement"/>
        
        <van-row v-if="source != null" class="bottom-button">
            <van-col span="4"></van-col>

            <van-col span="18">
                <van-button size="small" type="warning" round block @click="goToSource()">返 回</van-button>
            </van-col>

            <van-col span="4"></van-col>
        </van-row>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getAgreementContent } from '@/api/setting-api'

export default {
    async mounted() {
        this.source = this.$route.params.source
        this.type = this.$route.params.type

        if (this.agrSettingInit === false) {
            getAgreementContent().then((data) => {
                this.printDebug("agreement content setting data => ", data)
                
                this.setAgrSettings(data)
            }).catch(_ => {
                this.printDebug("get agreement content setting data error!!!!!")
            })
        }
    },

    data() {
        return {
            source: null,
            type: null
        }
    },

    computed: {
        ...mapState(['agrSettingInit', 'userAgreement', 'secretAgreement'])
    },

    methods: {
        ...mapMutations(['setAgrSettings']),

        goToSource() {
            this.$router.push({ name: this.source})
        }
    }
}

</script>

<style lang="less" scoped>

.main {
    margin: 0px 10px 0px 10px;
    
    /deep/ ul {
        list-style:disc inside !important;
    }

    /deep/ ol {
        list-style:decimal inside !important;
    }
}

.bottom-button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 20px;
}

</style>
