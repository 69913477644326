<template>
    <div class="main">
        <van-loading color="#1989fa" size="48px"></van-loading>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getQywxInfo, qywxLogin } from '@/api/qywx-api'

export default {
    mounted() {
        let wx_code = this.getUrlParam("code"); // 截取url中的code
        let state = this.getUrlParam("state");

        this.printDebug("wx_code => ", wx_code)
        this.printDebug("state => ", state)
        
        if (!wx_code) {
            (async () => {
                let qywxAccInfo = (await getQywxInfo()).data;

                if (qywxAccInfo.code !== 200) {
                    this.$toast.fail("配置信息错误")
                    return
                }
                
                let appid = qywxAccInfo.data.corpId;
                let agentid = qywxAccInfo.data.agentId;
                
                this.printDebug("appid = ", appid)
                this.printDebug("agentid = ", agentid)
                this.printDebug("window.location.href => ", window.location.href)
                
                let redirect = encodeURIComponent(window.location.href); //重定向回来的地址

                //成功重定向后地址栏中将会带有code，没有code的话，就跳转到微信官方链接上获取，获取成功后会再重定向回来。
                let url = "https://open.weixin.qq.com/connect/oauth2/authorize?" +
                            `appid=${appid}` + `&redirect_uri=${redirect}` + `&agentid=${agentid}` +
                            "&response_type=code&scope=snsapi_privateinfo&state=wx_offical#wechat_redirect";
                
                this.printDebug("jump href => ", url)
                            
                window.location.href = url
            })()
        } else {
            (async () => {
                let employee = (await qywxLogin(wx_code)).data.data;

                this.printDebug("employee => ", employee)

                this.setLoginFlag(true)
                this.setAccountType(this.ACCOUNT_TYPE_EMPLOYEE)
                this.setEmployee(employee)
                
                localStorage.setItem("type", this.ACCOUNT_TYPE_EMPLOYEE)
                localStorage.setItem("token", employee.token)

                this.goToHome();
            })()
        }
    },

    computed: {
        ...mapState(['openId'])
    },

    methods: {
        ...mapMutations(['setLoginFlag', 'setAccountType', 'setEmployee']),

        getUrlParam: function (name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            
            var r = window.location.search.substr(1).match(reg);

            if (r != null) 
                return unescape(r[2]);
            
            return null;
        },

        goToHome() {
            this.$router.push({ name: "home" })
        }
    }
}
</script>

<style lang="less" scoped>

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

</style>
