import Vue from "vue";
import Vuex from "vuex"

Vue.use(Vuex)

import { getVisitorStatistic, getEmployeeStatistic } from '@/api/statistic-api'

export default new Vuex.Store({
    state: {
        //employee：表示员工登陆，visitor：表示访客登陆。
        loginFlag: null,
        accountType: null,
        visitor: null,
        employee: null,
        openId: null,
        
        process: '-',
        finish: '-',
        record: '-',

        //设置相关
        basicSettingInit: false,
        systemName: null,
        companyLogo: null,
        homeWelcome: null,

        agreementStateInit: false,
        userAgreementState: false,
        secretAgreementState: false,

        agrSettingInit: false,
        userAgreement: null,
        userAgrReadFlag: false,
        secretAgreement: null,
        secretAgrReadFlag: false,
        
        ntcSettingInit: false,
        visitorNotice: null,
        visitorNtcReadFlag: false
    },

    actions: {
        setPanelNumber({commit, state}) {
            let record = {}

            if (state.accountType === "visitor") {
                getVisitorStatistic().then(({data}) => {
                    record.process = data.data.pendingApplies
                    record.finish = data.data.finishApplies
                    record.record = data.data.records

                    commit('setPanelNumber', record)
                }).catch((error) => {
                    // console.log("error =>", error)
                })
            } else if (state.accountType === "employee") {
                getEmployeeStatistic().then(({data}) => {
                    record.process = data.data.pendingApplies
                    record.finish = data.data.finishApplies
                    record.record = data.data.records

                    commit('setPanelNumber', record)
                }).catch((error) => {
                    // console.log("error =>", error)
                })
            }
        }
    },

    mutations: {
        setLoginFlag(state, flag) {
            state.loginFlag = flag
        },

        setAccountType(state, type) {
            state.accountType = type
        },

        setVisitor(state, data) {
            state.visitor = data
        },

        setEmployee(state, data) {
            state.employee = data
        },
        
        setOpenId(state, openId) {
            state.openId = openId
        },
        
        setPanelNumber(state, record) {
            state.process = record.process
            state.finish = record.finish
            state.record = record.record
        },
        
        clearPanelNumber(state) {
            state.process = '-'
            state.finish = '-'
            state.record = '-'
        },
        
        setBasicSettings(state, obj) {
            state.systemName = obj.systemName
            state.companyLogo = obj.companyLogo
            state.homeWelcome = obj.homeWelcome

            state.basicSettingInit = true
        },

        setAgreementState(state, obj) {
            state.userAgreementState = obj.userAgreementState
            state.secretAgreementState = obj.secretAgreementState

            state.agreementStateInit = true
        },

        setAgrSettings(state, obj) {
            state.userAgreement = obj.userAgreement
            state.userAgrReadFlag = obj.userAgrReadFlag
            state.userAgreementState = obj.userAgreementState

            state.secretAgreement = obj.secretAgreement
            state.secretAgrReadFlag = obj.secretAgrReadFlag
            state.secretAgreementState = obj.secretAgreementState

            state.agrSettingInit = true
            state.agreementStateInit = true
        },

        setNtcSettings(state, obj) {
            state.visitorNotice = obj.visitorNotice
            state.visitorNtcReadFlag = obj.visitorNtcReadFlag

            state.ntcSettingInit = true
        }
    },

    getters: {
        loginState(state) {
            return state.loginFlag === true
        }
    }
    
})
