<template>
    <div>
        <div class="header">
            <van-image style="width:120px; height: 120px" round :src="require('../../assets/result.png')"/>
            <p>提交成功</p>
        </div>
        
        <van-row style="margin-bottom:20px;margin-top:5px">
            <van-col span="6">
            </van-col>

            <van-col span="12">
                <van-button size="normal" round block type="info" @click="onClick('home')">
                    返回首页
                </van-button>
            </van-col>

            <van-col span="6">
            </van-col>
        </van-row>

        <van-row>
            <van-col span="6">

            </van-col>

            <van-col span="12">
                <!-- <van-button size="normal" type="danger"></van-button> -->
                <van-button size="normal" round block type="danger" @click="onClick('detail')">
                    查看申请 
                </van-button>
            </van-col>

            <van-col span="6">
            </van-col>
        </van-row>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['accountType'])
    },
    
    methods: {
        onClick(value) {
            if (value === 'home') {
                this.$router.push({ name: "home" })
            } else {
                if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
                    this.$router.push({ name: "employeerecords", params: {active : this.RECORD_TAB_PROCESS} })
                } else {
                    this.$router.push({ name: "visitorrecords", params: {active : this.RECORD_TAB_PROCESS} })
                }
            }
        }

    }
}
</script>

<style lang="less" scoped>

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 40vh;
}

</style>