<template>
    <div>
        <div class="header">
            <van-dialog class="qr-dialog" v-model="showQRDialog" :show-confirm-button="false" :closeOnClickOverlay="true">
                <VueQr draggable="false" :correctLevel="3" :dotScale="1" :margin="15" :size="256"
                        :text="qrText"/>
            </van-dialog>

            <van-form class="header-form">
                <div class="header-top"></div>

                <div v-if="this.accountType === this.ACCOUNT_TYPE_VISITOR">
                    <div v-for="(item, index) in employeeFormLabel" :key="index">
                        <van-field :name="item.label" :label="item.label" colon :readonly="true"
                                    v-model="form[item.prop]"/>
                    </div>
                </div>

                <div v-else>
                    <div v-for="(item, index) in visitorFormLabel" :key="index">
                        <van-field :name="item.label" :label="item.label" colon :readonly="true"
                                    v-model="form[item.prop]"/>
                    </div>
                </div>

                <div class="header-footer"></div>
            </van-form>
        </div>
        
        <div>
            <van-form class="content-form">
                <div class="content-top"></div>
                
                <van-field v-if="this.pinCode != null"
                            v-model="pinCode" readonly label="访客码" input-align="right"/>

                <van-field v-model="visitorName" readonly label="申请访客" input-align="right"/>

                <van-field v-model="employeeName" readonly label="被访员工" input-align="right"/>

                <van-field v-if="parentArea !== null" v-model="parentArea.name" readonly label="到访区域" input-align="right"/>

                <van-field v-if="subArea !== null" v-model="subArea.address" readonly label="到访地址" input-align="right"/>

                <van-field v-model="applyBeginTime" readonly label="开始时间" input-align="right"/>

                <van-field v-model="applyEndTime" readonly label="结束时间" input-align="right"/>
                
                <div v-for="(item, index) in applyForm" :key="index">
                    <van-field v-if="item.type !== 'accompany'&&item.type !== 'beginTime'&&item.type !== 'endTime'" 
                        v-model="item.value" input-align="right" :name="item.title" :label="item.title"/>
                </div>
                
                <div v-for="item in accompanies" :key="item.tel">
                    <van-field v-model="item.name" readonly label="同行访客" input-align="right"/>
                </div>
                
                <div class="content-footer"></div>
            </van-form>

            <van-form v-if="enterTime != null" class="content-form">
                <div class="content-top" style="font-weight: 550; font-size: 14px;">
                </div>
                
                <van-field v-model="enterTime" readonly label="签到时间" input-align="right"/>

                <van-field v-model="leaveTime" readonly label="签离时间" input-align="right"/>
                
                <div class="content-footer"></div>
            </van-form>
        </div>
        
        <div v-if="this.accountType === this.ACCOUNT_TYPE_EMPLOYEE">
            <van-form v-if="showTransfersList" class="content-form">
                <div class="content-top" style="font-weight: 550; font-size: 14px;">
                    转签列表
                </div>
                
                <div style="margin: 10px 10px 0px 10px;">
                    <van-steps :active="transfers.list.length" active-color="green" >
                        <van-step v-for="(item, index) in transfers.list" :key="index">
                            {{item}}
                        </van-step>
                    </van-steps>
                </div>

                <div class="content-footer">
                    <div v-for="(item, index) in transfers.data" :key="index">
                        <van-divider dashed
                            :style="{color: '#909399', borderColor: '#909399', padding: '0px 20px 0px 20px'}">
                            {{item.name}} 转签详情
                        </van-divider>

                        <van-field v-model="item.time" readonly label="转签时间" input-align="left"/>
                        <van-field v-model="item.comments" readonly type="textarea" rows="1" autosize label="转签备注" input-align="left"/>
                    </div>
                </div>
            </van-form>

            <van-form v-if="showMakeCopyList" class="content-form">
                <div class="content-top" style="font-weight: 550; font-size: 14px;">
                    抄送列表
                </div>

                <div v-for="(item, index) in makeCopies" :key="index">
                    <van-field v-model="item.value" readonly label="抄送人员" input-align="right"/>
                </div>

                <div class="content-footer">
                </div>
            </van-form>
            
            <van-form class="content-form">
                <div class="content-top" style="font-weight: 550; font-size: 14px;">
                    审批进度
                </div>

                <div style="margin: 10px 10px 0px 10px;">
                    <van-steps :active="approves.approveSteps" active-color="red" >
                        <van-step v-for="(item, index) in approves.list" :key="index">
                            {{item.employeeName}}
                        </van-step>
                    </van-steps>
                </div>
                
                <van-field v-model="approves.approveResult" readonly label="审批结果" input-align="right"/>
                <van-field v-model="approves.approveTime" readonly label="审批时间" input-align="right"/>
                
                <div class="content-footer">
                    <div v-for="(item, index) in approves.data" :key="index">
                        <van-divider dashed
                            :style="{color: '#909399', borderColor: '#909399', padding: '0px 20px 0px 20px'}">
                            {{item.employeeName}} 审批详情
                        </van-divider>

                        <van-field v-model="item.showResult" readonly label="审批结果" input-align="left"/>
                        <van-field v-model="item.approveTime" readonly label="审批时间" input-align="left"/>
                        <van-field v-model="item.comments" readonly type="textarea" rows="1" autosize label="审批意见" input-align="left"/>
                    </div>
                </div>
            </van-form>
        </div>

        <div v-else>
            <van-form class="content-form">
                <div class="content-top" style="font-weight: 550; font-size: 14px;">
                    审批进度
                </div>
                
                <van-field v-model="approves.approveResult" readonly label="审批结果" input-align="right"/>
                
                <van-field v-if="approves.approveEmployee != null" v-model="approves.approveEmployee" readonly label="审批员工" input-align="right"/>
                
                <van-field v-model="approves.approveTime" readonly label="审批时间" input-align="right"/>

                <van-field v-if="approves.approveComments != null" v-model="approves.approveComments" readonly label="审批备注" input-align="right"/>
                
                <div class="content-footer"></div>
            </van-form>
        </div>

        <div v-if="this.accountType === this.ACCOUNT_TYPE_EMPLOYEE">
            <van-row v-if="showGoToSourceButton" style="margin: 16px;">
                <van-col offset="1" span="22">
                    <van-button size="small" round block type="primary" @click="goToSource()">返回</van-button>
                </van-col>
            </van-row>
        </div>

        <div v-else>
            <van-row v-if="showGoToSourceButton" style="margin: 16px;">
                <van-col offset="1" span="10">
                    <van-button size="small" round block @click="goToSource()">返回</van-button>
                </van-col>
                
                <van-col offset="2" span="10">
                    <van-button size="small" round block type="primary" @click="showQRDialog=true">签到码</van-button>
                </van-col>
            </van-row>

            <van-row v-else style="margin: 16px;">
                <van-col offset="2" span="20">
                    <van-button size="small" round block type="primary" @click="showQRDialog=true">签到码</van-button>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import VueQr from "vue-qr";
import { mapState } from 'vuex'
import { getAreaList } from '@/api/area-api'
import { getVisitorByBatch } from '@/api/visitor-api'
import { getEmployeeByCode, getEmployeeByBatch } from '@/api/employee-api'
import {getApproves,getTransfers, getMakeCopies, getAccompanies} from '@/api/apply-api'

export default {
    components: {
        VueQr
    },

    mounted() {
        this.printDebug("[RecordDetail] route.params => ", this.$route.params)
        
        this.source = this.$route.params.source
        this.type = this.$route.params.type
        this.active = this.$route.params.active
        
        if (this.active === this.RECORD_TAB_ENTER) {
            this.record = this.$route.params.data
            this.apply = this.$route.params.data.apply
        } else {
            this.record = null
            this.apply = this.$route.params.data
        }

        //
        if (this.accountType === this.ACCOUNT_TYPE_VISITOR) {
            this.form = this.apply.employee

            if (this.record != null) {
                this.pinCode = this.record.pinCode
            } else {
                this.pinCode = this.apply.pinCode
            }
        } else {
            this.form = this.apply.visitor
        }
        
        //
        this.visitorName = this.apply.visitor.name
        this.employeeName = this.apply.employee.name

        if (this.apply.entryArea !== null) {
            (async () => {
                let areas = (await getAreaList()).data.data
                
                areas.forEach(parent => {
                    parent.areas.forEach(sub => {
                        if (sub.id === this.apply.entryArea) {
                            this.parentArea = parent;
                            this.subArea = sub
                        }
                    })
                })
            })();
        }

        this.applyBeginTime = this.apply.beginDate + ' ' + this.apply.beginTime.substring(0, 5)
        this.applyEndTime = this.apply.endDate + ' ' + this.apply.endTime.substring(0, 5)
        this.applyForm = this.apply.forms

        if (this.record != null) {
            this.enterTime = 
                this.record.enterDate + ' ' + this.record.enterTime.substring(0, 5)

            this.leaveTime = 
                this.record.leaveDate == null ? "用户没有签退" :
                    this.record.leaveDate + ' ' + this.record.leaveTime.substring(0, 5)
        }

        //
        this.getAccompaniesList();

        if (this.accountType === this.ACCOUNT_TYPE_EMPLOYEE) {
            this.getEmployeeApprovesList();
            this.getTransfersList();
            this.getMakeCopiesList();
        } else {
            this.getVisitorApprovesList();
        }
    },

    computed: {
        ...mapState(['accountType']),

        showMakeCopyList() {
            if (this.makeCopies.length > 0) {
                return true
            }

            return false
        },

        showTransfersList() {
            if (this.transfers.list.length > 0) {
                return true
            }

            return false
        },

        showGoToSourceButton() {
            if ((this.type === this.RECORD_OPS_DETAIL)&&(this.source != null)) {
                return true
            }

            return false
        },

        qrText() {
            return this.pinCode
        }
    },

    data() {
        return {
            showQRDialog : false,

            //传递过来的参数
            source: null,
            type: null,
            active: null,
            apply: null,
            record: null,
            
            //header部分
            form : {},
            employeeFormLabel : [
                {
                    prop : 'name',
                    label : '访问员工'
                },
                {
                    prop : 'tel',
                    label : '手机号码'
                },
                {
                    prop : 'departmentName',
                    label : '所在部门'
                }
            ],
            visitorFormLabel : [
                {
                    prop : 'name',
                    label : '访客姓名'
                },
                {
                    prop : 'tel',
                    label : '手机号码'
                },
                {
                    prop : 'company',
                    label : '所在公司'
                }
            ],

            //Apply 申请信息
            visitorName: null,
            employeeName: null,
            parentArea: null,
            subArea: null,
            applyBeginTime : '',
            applyEndTime : '',
            applyForm : [],

            enterTime: null,
            leaveTime: null,
            pinCode: null,

            //审批&转签&抄送&陪同
            approves: {
                approveResult: null,
                approveTime: null,
                approveSteps: null,
                approveEmployee: null,
                list: [],
                data: []
            },
            
            transfers: {
                list: [],
                data: []
            },

            makeCopies: [],
            accompanies: []
        }
    },

    methods: {
        goToSource() {
            this.$router.push({ name: this.source, 
                                params: {
                                    source: 'recorddetail',
                                    type: this.type,
                                    active: this.active} })
        },

        getShowTime(value) {
            if (value == null) {
                return
            }

            return value.substring(2, 10) + ' ' + value.substring(11, 16)
        },

        //访客审批列表，不需要查询审批员工的详细信息
        getVisitorApprovesList() {
            getApproves(this.apply.id).then(({data}) => {
                this.printDebug("approve data => ", data)

                if (data.code === 200) {
                    this.approves.data.push(...data.data)

                    switch (this.apply.state) {
                        case "PASS":
                            if (this.approves.data.length > 0) {
                                this.approves.approveResult = "审批通过"
                                this.approves.approveTime = 
                                    this.getShowTime(this.approves.data[this.apply.currentStep - 1].updateTime)
                                this.approves.approveComments = this.approves.data[this.apply.currentStep - 1].comments
                            } else {
                                this.approves.approveResult = "免审通过"
                                this.approves.approveTime = this.getShowTime(this.apply.createTime);
                            }
                            break
                        case "REJECT":
                            (async () => {
                                this.approves.approveResult = "审批拒绝";
                                this.approves.approveTime = 
                                    this.getShowTime(this.approves.data[this.apply.currentStep - 1].updateTime);
                                this.approves.approveComments = this.approves.data[this.apply.currentStep - 1].comments;

                                let employee = (await getEmployeeByCode(this.approves.data[this.apply.currentStep - 1].employeeCode)).data.data;
                                this.approves.approveEmployee = employee.name;
                            })();
                            break
                        case "CANCEL":
                            this.approves.approveResult = "撤销申请"
                            break
                        case "PROCESS":
                            this.approves.approveResult = "审批中..."
                            break
                        case "NONE":
                            this.approves.approveResult = "审批中..."
                            break
                    }
                }
            })
        },

        //员工审批列表，需要根据审批列表查询员工详细信息
        getEmployeeApprovesList() {
            let codes = []

            getApproves(this.apply.id).then(({data}) => {
                this.printDebug("approve data => ", data)

                if (data.code === 200) {
                    let approveData = data.data

                    approveData.forEach(item => {
                        if (!codes.includes(item.employeeCode)) {
                            codes.push(item.employeeCode)
                        }
                    })

                    this.printDebug("approve codes = ", codes)

                    if (codes.length < 1) {
                        this.approves.list = []
                        this.approves.list.push({"employeeName": "开始"})
                        this.approves.list.push({"employeeName": "免审"})
                        this.approves.list.push({"employeeName": "结束"})
                        this.approves.approveSteps = 2
                        
                        this.approves.approveResult = "免审通过"
                        this.approves.approveTime = this.getShowTime(this.apply.createTime);
                        return
                    }

                    getEmployeeByBatch(codes).then(({data}) => {
                        let employees = data.data
                        this.printDebug("apprvoe employees => ", employees)
                        
                        if (data.code === 200) {
                            approveData.forEach((item, index) => {
                                for (let i = 0; i < employees.length; i++) {
                                    if (employees[i].id === item.employeeId) {
                                        approveData[index].employeeName = employees[i].name
                                        break
                                    }
                                }

                                if (approveData[index].result !== 'WAITING') {
                                    this.approves.data.push({
                                        "employeeName": approveData[index].employeeName,
                                        "showResult": approveData[index].result === "PASS" ? "审批通过" : "审批拒绝",
                                        "approveTime": this.getShowTime(approveData[index].updateTime),
                                        "comments": approveData[index].comments
                                    })
                                }
                            })
                            
                            this.approves.list = []
                            this.approves.list.push({"employeeName": "开始"})
                            this.approves.list.push(...approveData)
                            this.approves.list.push({"employeeName": "结束"})
                            
                            switch (this.apply.state) {
                                case "PASS":
                                    this.approves.approveResult = "审批通过"
                                    this.approves.approveSteps = this.apply.currentStep + 1
                                    this.approves.approveTime = 
                                        this.getShowTime(this.approves.list[this.apply.currentStep].updateTime)
                                    break
                                case "REJECT":
                                    this.approves.approveResult = "审批拒绝"
                                    this.approves.approveSteps = this.apply.currentStep
                                    this.approves.approveTime = 
                                        this.getShowTime(this.approves.list[this.apply.currentStep].updateTime)
                                    break
                                case "CANCEL":
                                    this.approves.approveResult = "撤销申请"
                                    break
                                case "PROCESS":
                                case "NONE":
                                    this.approves.approveResult = "审批中..."
                                    this.approves.approveSteps = this.apply.currentStep -1
                                    this.approves.approveTime = 
                                        this.getShowTime(this.approves.list[this.apply.currentStep].updateTime)
                                    break
                            }
                        }
                    })
                }
            })
        },

        //转签列表
        getTransfersList() {
            let codes = []

            getTransfers(this.apply.id).then(({data}) => {
                this.printDebug("transfer data => ", data)

                if (data.code === 200) {
                    let transferData = data.data
                    
                    transferData.forEach(item => {
                        if (!codes.includes(item.fromEmployeeCode)) {
                            codes.push(item.fromEmployeeCode)
                        }

                        if (!codes.includes(item.toEmployeeCode)) {
                            codes.push(item.toEmployeeCode)
                        }
                    })

                    if (codes.length < 1) {
                        return
                    }

                    getEmployeeByBatch(codes).then(({data}) => {
                        this.printDebug("employees =>", data)

                        let employees = data.data
                        let length = transferData.length

                        for (let i = 0; i < length; i++) {
                            let item = transferData[i]
                            let fromEmployee, toEmployee
                            
                            employees.forEach(employee => {
                                if (employee.id === item.fromEmployeeId) {
                                    fromEmployee = employee
                                }

                                if (employee.id === item.toEmployeeId) {
                                    toEmployee = employee
                                }
                            })

                            if (i === 0) {
                                this.transfers.list.push(fromEmployee.name)
                                this.transfers.list.push(toEmployee.name)
                            } else {
                                this.transfers.list.push(toEmployee.name)
                            }

                            this.transfers.data.push({
                                "name": fromEmployee.name,
                                "time": this.getShowTime(item.createTime),
                                "comments": item.comments
                                // "comments": "两个黄鹂鸣翠柳，一行白鹭上青天。窗含西岭千秋雪，门泊东吴万里船。"
                            })
                        }
                    })
                }
            })
        },

        //抄送列表
        getMakeCopiesList() {
            let codes = []

            getMakeCopies(this.apply.id).then(({data}) => {
                this.printDebug("makecopy data => ", data)

                if (data.code === 200) {
                    data.data.forEach(item => {
                        if (!codes.includes(item.employeeCode)) {
                            codes.push(item.employeeCode)
                        }
                    })

                    if (codes.length < 1) {
                        return
                    }

                    getEmployeeByBatch(codes).then(({data}) => {
                        // this.printDebug("makecopy employees =>", data)
                        if (data.code === 200) {
                            data.data.forEach(item => {
                                this.makeCopies.push({"value": item.name})
                            })
                        }
                    })
                }
            })
        },

        //陪同访客
        getAccompaniesList() {
            let codes = []

            getAccompanies(this.apply.id).then(({data}) => {
                this.printDebug("accompany data => ", data)

                if (data.code === 200) {
                    data.data.forEach(item => {
                        if (!codes.includes(item.visitorCode)) {
                            codes.push(item.visitorCode)
                        }
                    })

                    if (codes.length < 1) {
                        return
                    }
                    
                    getVisitorByBatch(codes).then(({data}) => {
                        if ((data.code === 200)&&(data.data != null)) {
                            this.accompanies.push(...data.data)
                            // this.accompanies.forEach((item) => {
                            //     item.value = item.name + " | " + item.tel
                            // })
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>

.qr-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(81, 126, 230);

    .header-form {
        margin: 20px 20px 20px 20px;

        background-color: white;
        
        border-radius: 15px;
        border:0.5px solid #efefef;
        box-shadow: 0 0 15px #606266;

        .van-cell {
            /deep/.van-field__label {
                padding: 0px 0px 0px 10px;
                margin: 0px 0px 0px 10px;
            }
        }

        div {
            margin: 0px;
            padding: 0px;
        }

        .header-top {
            margin-top: 20px;
        }

        .header-footer {
            margin-bottom: 20px;
        }

        /deep/.van-field__label {
            // color: red;
            font-weight: 550;
        }
    }
}

.content-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin: 20px 10px 20px 10px;

    background-color: white;
    
    border-radius: 15px;
    border:0.5px solid #efefef;
    box-shadow: 0 0 15px #606266;
    
    .van-cell {
        /deep/.van-field__label {
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 0px 10px;
        }

        /deep/.van-field__value {
            padding: 0px 10px 0px 0px;
            margin: 0px 10px 0px 0px;
        }
    }

    div {
        margin: 0px;
        padding: 0px;
    }

    .content-top {
        margin: 10px 10px 10px 10px;
    }

    .content-footer {
        margin: 10px 10px 10px 10px;
    }
}

.van-tag {
    height: 32px;
}

</style>
