<template>
    <div>
        <RecordDetail></RecordDetail>

        <van-form class="checkout-form">
            <div class="title">
                选择签离人员
            </div>
            
            <div class="area">
                <van-checkbox-group  v-model="checkoutIds">
                    <van-checkbox class="area-item" shape="square" v-for="(item, index) in checkoutList" :name="item.id" :key="index">
                        {{item.name}} | {{item.tel}} | {{item.company}}
                    </van-checkbox>
                </van-checkbox-group>
            </div>
            
            <van-row class="footer">
                <van-col span="1"></van-col>

                <van-col span="10">
                    <van-button size="small" round block style="background: #efefef;" @click="goToSource()">返回</van-button>
                </van-col>

                <van-col span="2"></van-col>
                
                <van-col span="10">
                    <van-button size="small" round block type="primary" @click="checkoutSubmit()">提交</van-button>
                </van-col>

                <van-col span="1"></van-col>
            </van-row>
		</van-form>
    </div>
</template>

<script>
import RecordDetail from "./RecordDetail"

import { getVisitorByBatch } from "@/api/visitor-api"
import {confirmCheckOut } from "@/api/record-api"

export default {
    components: {
        RecordDetail
    },

    mounted() {
        this.printDebug("[checkout]$route.params = ", this.$route.params)

        this.source = this.$route.params.source
        this.type = this.$route.params.type
        this.active = this.$route.params.active
        this.apply = this.$route.params.data.apply
        
        //找到所有要签离人员
        let codes = []
        let accompaniesStr = this.apply.accompanies
        
        if (accompaniesStr != null) {
            codes.push(...accompaniesStr.split("|"))
        }

        if (codes.length >= 1) {
            (async () => {
                let data = (await getVisitorByBatch(codes)).data;
                
                if ((data.code === 200)&&(data.data != null)) {
                    this.checkoutList.push(...data.data)
                }
            })()
        }

        this.checkoutList.unshift(this.apply.visitor)

        this.printDebug("this.checkoutList => ", this.checkoutList)
    },

    data() {
        return {
            checkoutIds: [],
            checkoutList: []
        }
    },

    methods: {
        goToSource() {
            this.$router.push({ name: this.source, 
                                params: {
                                    source: 'checkout',
                                    type: this.type,
                                    active: this.active} })

        },

        checkoutSubmit() {
            this.printDebug("this.checkoutIds = ",  this.checkoutIds)
            
            confirmCheckOut(this.apply.id, this.checkoutIds).then(({data}) => {
                this.printDebug("data = ", data)

                if (data.code === 200) {
                    // this.$toast.success("签离成功")
                    // this.goToSource()
                    this.$router.push({ name: "operateresult", 
                        params: {source: this.source, type : this.type, active: this.active} })
                }
            }).catch(_ => {
                this.$toast.fail(this.NETWORK_ERROR_MSG)
            })
        }
    }
}
</script>

<style lang="less" scoped>

.checkout-form {
    margin: 20px 10px 20px 10px;

    background-color: white;
    
    border-radius: 15px;
    // border:0.5px solid #efefef;
    border:0.5px solid rgb(81, 126, 230);
    box-shadow: 0 0 15px #606266;
    
    div {
        margin: 0px;
        padding: 0px;
    }
    
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-top: 10px;
        margin-bottom: 10px;
        
        font-size: 18px !important;
        font-weight: 550;
    }

    .footer {
        margin-bottom: 20px;
    }
    
    .area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
        margin: 10px 0px 30px 0px;
        
        .area-item {
            // border-radius: 15px;
            box-shadow: 0 0 15px #606266;
            
            margin: 10px 10px 0px 10px;
            padding: 10px 0px 10px 0px;
        }

        /deep/.van-checkbox__icon {
            margin-left: 20px;
            margin-right: 5px;
        }
    }
}

</style>